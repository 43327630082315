import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PaySelected from '../../components/modal/payselected';
import InfoBar from '../../components/orders/InfoBar';
import ItemCard from '../../components/orders/ItemCard';
import { saleEntries } from './mockdata';
// import { OrderEntries } from "./OrderEntries.js";
import './orders.scss';

const SessionOrders = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Container className="item-wrapper card-list">
        <InfoBar />

        <Row className="card-content">
          {/* {Paid items } */}
          <Col className="" xs={12} sm={12} md={2}>
            {saleEntries.paidItems.map((item) => {
              return (
                <div className="gy-3">
                  <ItemCard
                    dishName={item?.dishName}
                    setShow={setShow}
                    basicItemPrice={item?.basicItemPrice}
                    quantity={item?.quantity}
                    hasIngredients={
                      'mainIngredients' in item?.ingredients ||
                      'removedStandardIngredients' in item?.ingredients ||
                      'extraIngredients' in item?.ingredients
                    }
                    ingredients={item?.ingredients}
                    totalPricewithExtras={34.87}
                  />
                </div>
              );
            })}
          </Col>

          {/* {Scanned Items} */}
          <Col xs={12} sm={12} md={5}>
            <Row className="gy-3">
              {saleEntries.scannedItems.map((item) => {
                return (
                  <Col xs={12} sm={12} md={6}>
                    <ItemCard
                      dishName={item?.dishName}
                      setShow={setShow}
                      basicItemPrice={item?.basicItemPrice}
                      quantity={item?.quantity}
                      hasIngredients={
                        'mainIngredients' in item?.ingredients ||
                        'removedStandardIngredients' in item?.ingredients ||
                        'extraIngredients' in item?.ingredients
                      }
                      ingredients={item?.ingredients}
                      totalPricewithExtras={item?.totalPricewithExtras}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>

          {/* {UnScanned Items} */}
          <Col xs={12} sm={12} md={5}>
            <Row className="gy-3">
              {saleEntries.unScannedItems.map((item) => {
                return (
                  <Col xs={12} sm={12} md={6}>
                    <ItemCard
                      dishName={item?.dishName}
                      setShow={setShow}
                      basicItemPrice={item?.basicItemPrice}
                      quantity={item?.quantity}
                      hasIngredients={
                        'mainIngredients' in item?.ingredients ||
                        'removedStandardIngredients' in item?.ingredients ||
                        'extraIngredients' in item?.ingredients
                      }
                      ingredients={item?.ingredients}
                      totalPricewithExtras={item?.totalPricewithExtras}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
      <PaySelected show={show} setShow={setShow} />
    </>
  );
};

export default SessionOrders;
