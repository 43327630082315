import React from 'react';
import { Container } from 'react-bootstrap';
import './processDone.scss';
import { useNavigate } from 'react-router-dom';

const ProcessDone = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container className="bg-white">
        <div>
          <div className="text-center">
            <div className="pt-4 ">
              <div className="position-relative text-heading">EINCHECKEN</div>
            </div>
            <div className="d-flex align-item-center justify-content-center pt-5 mt-3">
              <div className="card-img ">
                <img src="img/vapiano-atm-cards.png" className="img-fluid" alt="" />
              </div>
              <div className="d-flex align-item-center justify-content-center pt-3">
                <div className="btn border-none success-icon ">
                  <img src="img/success-icon.png" className="img-fluid" alt=""></img>
                </div>
              </div>
            </div>
            <div className="text-heading">
              <div className="">DIE GRUPPE WURDE</div>
              <div className="">ERFOLGREICH REGISTRIERT</div>
            </div>
            <div className="pt-4">
              <div
                className="btn close-btn pt-2 pb-2 ps-4 pe-4"
                onClick={() => navigate('/dashboard')}
              >
                Done
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ProcessDone;
