import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, ListGroup } from 'react-bootstrap';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

const CountCard = ({
  card_img_placeholder,
  countData,
  pagerNumber,
  ScanCardsColTitle,
  isPager,
  trashCan,
  setpagerNumber
}) => {
  const handleDelete=()=>{
    setpagerNumber("")
  }
  return (
    <>
      <Col className=" rounded align-self-start" xs={12} sm={12} md={2}>
        <Card className="bg-card rounded pt-2 pb-3">
          <Card.Body>
            <Card.Title>
              <Card.Img
                variant="top"
                src={`img/${card_img_placeholder}`}
                className="img-responsive img-card-title mt-2"
                width={50}
              />
            </Card.Title>
            <Card.Text className="fs-1 fw-bold m-0">{countData}</Card.Text>
          </Card.Body>
        </Card>
        {isPager ? (
          <Card className="bg-card rounded my-4 pt-2 pb-3">
            <Card.Body className="p-2">
              <div className="d-flex align-items-center justify-content-between card-head">
                <Card.Title>{ScanCardsColTitle}</Card.Title>
                <Card.Img
                  variant="top"
                  src={`img/${card_img_placeholder}`}
                  className="img-card-title img-responsive"
                  width={50}
                />
              </div>
              <Card.Text className="m-0 card-content">
                <ListGroup variant="" className="bg-white ">
                  {pagerNumber
                    ? [pagerNumber]?.map((id, index) => (
                        <>
                          <ListGroup.Item
                            className="border-bottom px-2 d-flex justify-content-between fs-5 align-items-baseline h-100"
                            key={index}>
                            <span className="">{id}</span>{' '}
                            {trashCan === true ? (
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                onClick={handleDelete}
                                className="pointer-hand"
                              />
                            ) : null}
                          </ListGroup.Item>
                          <div className="d-flex justify-content-center">
                            <div className="black-line"></div>
                          </div>
                        </>
                      ))
                    : null}
                </ListGroup>
              </Card.Text>
            </Card.Body>
          </Card>
        ) : null}
      </Col>
    </>
  );
};

export default CountCard;
