import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import ModalInterface from '../core/ModalInterface';
import Dailer from '../login/dailer';
import { v4 as uuidv4 } from 'uuid';
import './modalStyles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loadOrdersBySession, payOrder } from '../../store/actions/orderAction';
import { useNavigate } from 'react-router-dom';

const PaySelected = ({ show, setShow, total, order, isPaySelected }) => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [tip, setTip] = useState(null);
  const [tipButtonColor, setTipButtonColor] = useState(null);
  const [customTip, setCustomTip] = useState(null);
  const [isCustomTip, setIsCustomTip] = useState(false);
  const [remaningAmount, setRemaningAmount] = useState(null);
  const [passcode, setPasscode] = useState('');
  const loader = useSelector((state) => state.order.payWaiterLoader);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const notifyKitchen = (finalBill) => {
    let msgDetails = `${new Date()} | Pay to Waiter | Table Name: ${
      order?.tableNumber
    } | Order Total: €${finalBill?.toFixed(2)}`; //Tip Amount: €${(total - subAmount).toFixed(2)
    msgDetails = msgDetails + `| Table No: ${order?.tableNumber}`;
    return msgDetails;
  };
  const paywaiterHandler = () => {
    if (!paymentMethod) {
      alert('Please Select Payment Method');
      return;
    }
    if (!passcode) {
      alert('Please Add Given Amount');
      return;
    }
    let obj = {
      paymentMethod,
      tip,
      total,
      payed: Number((Number(total) + Number(tip)).toFixed(2))
    };
    let finalBill = Number((Number(total) + Number(tip)).toFixed(2));
    if (order?.payment_status !== 'paid') {
      const payload = {
        order_id: order._id,
        paymentMethod: paymentMethod,
        type: 'pay_all',
        paymentAmount: finalBill,
        divisionCount: order.division_count,
        tipAmount: Number(Number(tip).toFixed(2)),
        min_TTL: 0,
        tableInfo: {
          businessLocationId: order.businessLocationId,
          tableNumber: order.tableNumber,
          clientCount: false
        },
        sessionInfo: order.sessionId,
        salesEntries: order.salesEntries
      };
      if (!isPaySelected) {
        let filterdOrders = order.salesEntries.filter((ordr) => ordr.payment_status !== 'paid');
        if (filterdOrders.length) {
          payload.salesEntries = filterdOrders;
        } else {
          alert('Nothing To Pay');
          navigate('/payment');
        }
      }

      dispatch(payOrder(payload)).then((res) => {
        if (res) {
          navigate('/payment');
        }
      });
      // const waiterPayload = {
      //   businessLocationId: String(order?.businessLocationId),
      //   thirdPartyReference: uuidv4(),
      //   orderType: 'served',
      //   sessionID: order?.sessionId,
      //   tableNumber: Number(order?.tableNumber),
      //   orderNote: notifyKitchen(finalBill),
      //   totalAmount: finalBill
      // };
      // dispatch(payWaiter(waiterPayload)).then((res)=>{
      //   if(res){
      //     navigate("/dashboard")
      //   }
      // });
    } else {
      alert('Oops..! This Order has been paid.');
    }
  };
  const resetStates = () => {
    setPaymentMethod('');
    setTip(null);
    setTipButtonColor(null);
    setPasscode('');
    setRemaningAmount(null);
    setIsCustomTip(false);
    setCustomTip(null);
    setShow(false);
  };
  const tipHandler = (perecntage) => {
    setTipButtonColor(perecntage);
    setTip(Number((Number(total) * Number(perecntage)).toFixed(2)));
    setIsCustomTip(false);
    setCustomTip(null);
  };
  const customTipHandler = (value) => {
    const regex = /^[0-9]+(\.[0-9]*)?$/;
    const isValid = regex.test(value);
    if (isValid || value === '') {
      setTipButtonColor(2);
      setCustomTip(value);
      setTip(value);
    }
  };
  const getPaymentHandler = () => {
    let toBePayedAmount = total;
    if (tip) {
      toBePayedAmount = Number((Number(total) + Number(tip)).toFixed(2));
    }

    let amount = (Number(passcode) - Number(toBePayedAmount)).toFixed(2);
    if (amount > 0) {
      setRemaningAmount(`-${Math.abs(amount)}`);
      return;
    }
    if (amount < 0) {
      setRemaningAmount(`+${Math.abs(amount)}`);
      return;
    }
    setRemaningAmount('');
  };
  useEffect(() => {
    getPaymentHandler();
  }, [passcode, tip, total]);
  return (
    <>
      <ModalInterface
        display={show}
        size="xl"
        style={{ position: 'relative' }}
        handleClose={() => {
          setShow(false);
          resetStates();
        }}
        bodyStyles={{ maxHeight: '100%', overflowY: 'scroll' }}
        content={
          <>
            <div
              className="close-icon bg-gray"
              onClick={() => {
                setShow(false);
                resetStates();
              }}>
              X
            </div>
            <Row>
              <h1>
                <b className="d-flex justify-content-center"> Total Items Amount : {total}</b>
              </h1>
            </Row>
            <Row className="p-2">
              <Col md="4" lg="4" xs="12" sm="12">
                <div
                  onClick={() => {
                    setPaymentMethod('CASH');
                  }}
                  className={`stations-btn ${
                    paymentMethod == 'CASH' ? 'bg-blue' : 'bg-gray'
                  }  my-2`}>
                  <span className="font-32">CASH</span>
                </div>
                {/* <div
                  onClick={() => {
                    setPaymentMethod('IKGIFT');
                  }}
                  className={`stations-btn ${
                    paymentMethod == 'IKGIFT' ? 'bg-blue' : 'bg-gray'
                  }  my-2`}>
                  <span className="font-32">IKGIFT</span>
                </div> */}
                <div
                  onClick={() => {
                    setPaymentMethod('CARD');
                  }}
                  className={`stations-btn ${
                    paymentMethod == 'CARD' ? 'bg-blue' : 'bg-gray'
                  }  my-2`}>
                  <span className="font-32">CARD</span>
                </div>
                <div
                  onClick={() => {
                    setPaymentMethod('Edenred');
                  }}
                  className={`stations-btn ${
                    paymentMethod == 'Edenred' ? 'bg-blue' : 'bg-gray'
                  }  my-2`}>
                  <span className="font-32">Edenred</span>
                </div>
                <div
                  onClick={() => {
                    setPaymentMethod('IKDEBT');
                  }}
                  className={`stations-btn ${
                    paymentMethod == 'IKDEBT' ? 'bg-blue' : 'bg-gray'
                  }  my-2`}>
                  <span className="font-32">IKDEBT</span>
                </div>
                <div
                  onClick={() => {
                    setPaymentMethod('Sodexo');
                  }}
                  className={`stations-btn ${
                    paymentMethod == 'Sodexo' ? 'bg-blue' : 'bg-gray'
                  }  my-2`}>
                  <span className="font-32">Sodexo</span>
                </div>
              </Col>
              <Col md="4" lg="4" xs="12" sm="12">
                <div className="d-flex justify-content-center p-2 ">
                  <h1>TRINKGELD</h1>
                </div>
                <div className="d-flex">
                  <div
                    onClick={() => {
                      tipHandler(0.05);
                    }}
                    className={`euror-btn  m-1 ${
                      Number(tipButtonColor) == 0.05 ? 'bg-blue' : 'bg-light-gray'
                    }`}>
                    <span className="font-32">5 %</span>
                  </div>
                  <div
                    onClick={() => {
                      tipHandler(0.1);
                    }}
                    className={`euror-btn  m-1 ${
                      Number(tipButtonColor) == 0.1 ? 'bg-blue' : 'bg-light-gray'
                    }`}>
                    <span className="font-32">10 %</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    onClick={() => {
                      tipHandler(0.15);
                    }}
                    className={`euror-btn  m-1 ${
                      Number(tipButtonColor) == 0.15 ? 'bg-blue' : 'bg-light-gray'
                    }`}>
                    <span className="font-32">15 %</span>
                  </div>
                  <div
                    onClick={() => {
                      tipHandler(0.2);
                    }}
                    className={`euror-btn  m-1 ${
                      Number(tipButtonColor) == 0.2 ? 'bg-blue' : 'bg-light-gray'
                    }`}>
                    <span className="font-32">20 %</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    onClick={() => {
                      tipHandler(0.25);
                    }}
                    className={`euror-btn  m-1 ${
                      Number(tipButtonColor) == 0.25 ? 'bg-blue' : 'bg-light-gray'
                    }`}>
                    <span className="font-32">25 %</span>
                  </div>
                  <div
                    className={`euror-btn  m-1 ${
                      Number(tipButtonColor) === 2 ? 'bg-blue' : 'bg-gray'
                    }`}>
                    {isCustomTip ? (
                      <input
                        className="euror-btn bg-blue m-1 font-32 "
                        onChange={(e) => customTipHandler(e.target.value)}
                        value={customTip}
                        style={{ outline: '#6f6f6e', border: 'none' }}
                        type="text"
                      />
                    ) : (
                      <span
                        className="font-32"
                        style={{ width: '100%', textAlign: 'center' }}
                        onClick={() => {
                          setIsCustomTip(true), setTipButtonColor(2);
                        }}>
                        ...
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className="d-flex">
                  <div
                    onClick={() => {
                      setTipButtonColor(0.3), setTip(Number(0.3));
                    }}
                    className={`euror-btn  m-1 ${
                      Number(tipButtonColor) == 0.3 ? 'bg-blue' : 'bg-light-gray'
                    }`}>
                    <span>0,30 €</span>
                  </div>
                  <div
                    onClick={() => {
                      setTipButtonColor(5.3), setTip(Number(5.3));
                    }}
                    className={`euror-btn  m-1 ${
                      Number(tipButtonColor) == 5.3 ? 'bg-blue' : 'bg-light-gray'
                    }`}>
                    <span>5,30 €</span>
                  </div>
                </div> */}
                {/* <div className="d-flex">
                  <div
                    onClick={() => {
                      setTipButtonColor(12.47), setTip(Number(12.47));
                    }}
                    className={`euror-btn  m-1 ${
                      Number(tipButtonColor) == 12.47 ? 'bg-blue' : 'bg-light-gray'
                    }`}>
                    <span>12,47 €</span>
                  </div>
                  <div className="euror-btn bg-gray m-1">
                    <span>...</span>
                  </div>
                </div>
                <div className="d-flex justify-content-center ">
                  <span>RÜCKGLED</span>
                </div> */}
                <div className="d-flex justify-content-center p-2 ">
                  <h1>To Be Payed : {Number((Number(total) + Number(tip)).toFixed(2))}</h1>
                </div>
                <div className="five-euro-btn bg-light-blue my-2 p-2">
                  <span className="font-32">{remaningAmount ? remaningAmount + '€' : null} </span>
                </div>
                <div className="d-flex justify-content-center">
                  {loader ? (
                    <div className="action-blue-btn bg-blue m-1">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    </div>
                  ) : (
                    <div
                      disabled={loader}
                      onClick={paywaiterHandler}
                      className="action-blue-btn bg-blue m-1 p-2">
                      <span className="font-32">Done</span>
                    </div>
                  )}
                  {/* <div className="action-blue-btn f-12 bg-blue m-1">
                    <span>OPEN KASSE</span>
                  </div> */}
                </div>
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <div className="d-flex justify-content-center">
                  <Dailer
                    // onLoginHandler={getPaymentHandler}
                    passcode={passcode}
                    setPasscode={setPasscode}
                    isPayment={true}
                  />
                </div>
              </Col>
            </Row>
          </>
        }
        cartFooter={<></>}
        className={'text-dark pt-2'}
        closeEvent={() => {
          setShow(false);
          resetStates();
        }}
      />
    </>
  );
};

export default PaySelected;
