import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ActionBtn from './actionbtn';
import BtnLogin from './btnlogin';

const Dailer = ({ onLoginHandler, loading, passcode, setPasscode, isPayment }) => {
  const onClickHandler = (e) => {
    let value = passcode + String(e?.target?.value);
    if (isPayment) {
      const regex = /^[0-9]+(\.[0-9]*)?$/;
      const isValid = regex.test(value);
      if (isValid || value === '') {
        setPasscode(value);
      }
    } else {
      setPasscode(value);
    }
  };
  const onBackHandler = () => {
    setPasscode(passcode.slice(0, -1));
  };

  return (
    <>
      <Row className="w-100 m-auto">
        <Col lg="12" md="12" sm="12" xs="12">
          <div className="dailer p-3 h-100 calculator-wrapper">
            <input
              value={passcode}
              type="text"
              className={`d-text p-2 text-white1 ${isPayment ? 'font-28' : ''}`}
              placeholder="Enter Number"
            />
            <div className="pt-3 row-btn d-flex justify-content-between">
              <BtnLogin onClick={onClickHandler} nbr={1} />
              <BtnLogin onClick={onClickHandler} nbr={2} />
              <BtnLogin onClick={onClickHandler} nbr={3} />
            </div>
            <div className="py-1 row-btn d-flex justify-content-between">
              <BtnLogin onClick={onClickHandler} nbr={4} />
              <BtnLogin onClick={onClickHandler} nbr={5} />
              <BtnLogin onClick={onClickHandler} nbr={6} />
            </div>
            <div className="py-1 row-btn d-flex justify-content-between">
              <BtnLogin onClick={onClickHandler} nbr={7} />
              <BtnLogin onClick={onClickHandler} nbr={8} />
              <BtnLogin onClick={onClickHandler} nbr={9} />
            </div>
            <div
              className={`py-1 row-btn d-flex ${
                isPayment ? 'justify-content-center' : 'justify-content-between'
              }`}>
              {!isPayment ? <BtnLogin onClick={onClickHandler} nbr={'#'} /> : null}
              <BtnLogin onClick={onClickHandler} nbr={0} />
              {isPayment ? <BtnLogin classname="mx-2" onClick={onClickHandler} nbr={'.'} /> : null}
              {!isPayment ? <BtnLogin onClick={onClickHandler} nbr={'*'} /> : null}
            </div>
            <div className="py-1 row-btn d-flex justify-content-between">
              <ActionBtn loading={loading} onClick={onBackHandler} action={'back'} />
              <ActionBtn
                passcode={passcode}
                loading={loading}
                onClick={onLoginHandler}
                isPayment={isPayment}
                action={'enter'}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dailer;
