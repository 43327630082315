import React from 'react';
import { Navbar } from 'react-bootstrap';

const Footer = () => {
  return (
    <div className="d-none d-md-block">
      {' '}
      <Navbar
        fixed="bottom"
        expand="sm"
        className="nav-index"
        // className={`w-100 ${
        //   window.location.pathname === "/login" ? "bgColor-gray" : "bg-white"
        // }`}
      >
        <div className="ps-5">
          <div>
            <img src="../../img/logo.png" alt=""></img>
          </div>
          <div>
            <img src="../../img/operate.png" alt=""></img>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Footer;
