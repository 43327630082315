import React from 'react';
import './exitBtn.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const ExitBtn = () => {
  let history = useNavigate();
  return (
    <div className="position-absolute exit-icon ">
      <div
        className="btn exit-btn d-flex align-item-center justify-content-center"
        onClick={() => history("/dashboard")}
      >
        <FontAwesomeIcon className="text-white" icon={faArrowLeft} />
      </div>
    </div>
  );
};

export default ExitBtn;
