import { Card, ListGroup } from 'react-bootstrap';

const ItemCard = ({
  dishName,
  basicItemPrice,
  ingredients,
  quantity,
  hasIngredients,
  totalPricewithExtras,
  setShow,
  greenItems,
  redItems,
  subLineItems,
  onClick,
  selected,
  disabled
}) => {
  const { mainIngredients, removedStandardIngredients, extraIngredients } = ingredients;

  return (
    <>
      <Card
        onClick={!disabled&&onClick}
        style={selected ? { border: '8px solid green' } : {}}
        className="rounded card-list position-relative my-2">
        <div className={selected || disabled ? 'item-overlay' : 'd-none'}>
         {!disabled? <Card.Img
            variant="top"
            src={`img/check.png`}
            className="img-responsive img-card-title mt-2"
            width={50}
          />:null}
        </div>
        <Card.Body className="d-flex flex-column h-100 p-0 rounded">
          <div
            className="d-flex align-items-start justify-content-between card-head text-uppercase text-white"
            style={selected ? { borderRadius: '0px' } : { height: 'fit-content' }}>
            <Card.Title
              onClick={() => {
                setShow(true);
              }}>
              {dishName}
            </Card.Title>
            {basicItemPrice ? <Card.Title>{basicItemPrice} €</Card.Title> : null}
            {!hasIngredients && <Card.Title>{quantity}</Card.Title>}
          </div>
          {/* {subLineItems.map((ing) => {
                return (
                  <div className="border-bottom bg-light p-2 d-flex justify-content-between fs-4 align-items-center h-100 text-uppercase">
                    <ListGroup.Item>
                      {' '}
                      <b>{'>'}</b> {ing?.itemName}
                    </ListGroup.Item>
                  </div>
                );
              })} */}
          <Card.Text className="m-0">
            <ListGroup variant="" className="bg-card removedStandardIngredients">
              {redItems?.map((ing, index) => (
                <ListGroup.Item
                  className="border-bottom px-4 d-flex justify-content-between fs-5 align-items-center h-100 text-uppercase text-white"
                  key={index}>
                  <span className="">
                    {' '}
                    <b className="pe-2">-</b> {ing?.itemName}
                  </span>{' '}
                  <span>{ing?.itemPrice} €</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Text>
          <Card.Text style={{ margin: '0px', padding: '0px' }}>
            <ListGroup variant="" className="bg-vapiano-prim text-success">
              {greenItems?.map((ing, index) => (
                <ListGroup.Item
                  className="border-bottom px-4 d-flex justify-content-between fs-5 align-items-center h-100 text-uppercase"
                  key={index}>
                  <span className="">
                    {' '}
                    <b className="pe-2">+</b> {ing?.itemName}
                  </span>{' '}
                  <span>{ing?.itemPrice} €</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Text>
          {hasIngredients && (
            <>
              {mainIngredients.map((ing) => {
                return (
                  <div className="border-bottom bg-light p-2 d-flex justify-content-between fs-4 align-items-center h-100 text-uppercase">
                    <ListGroup.Item>
                      {' '}
                      <b>{'>'}</b> {ing.name}
                    </ListGroup.Item>
                  </div>
                );
              })}
              {/* <Card.Text className="m-0">
                <ListGroup variant="" className="bg-card removedStandardIngredients">
                  {redItems?.map((ing, index) => (
                    <ListGroup.Item
                      className="border-bottom px-4 d-flex justify-content-between fs-5 align-items-center h-100 text-uppercase text-white"
                      key={index}
                    >
                      <span className="">
                        {' '}
                        <b className="pe-2">-</b> {ing?.itemName}
                      </span>{' '}
                      <span>{ing?.itemPrice} €</span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Text>
              <Card.Text style={{ margin: '0px', padding: '0px' }}>
                <ListGroup variant="" className="bg-vapiano-prim text-success">
                  {greenItems?.map((ing, index) => (
                    <ListGroup.Item
                      className="border-bottom px-4 d-flex justify-content-between fs-5 align-items-center h-100 text-uppercase"
                      key={index}
                    >
                      <span className="">
                        {' '}
                        <b className="pe-2">+</b> {ing?.itemName}
                      </span>{' '}
                      <span>{ing?.itemPrice} €</span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Text> */}
            </>
          )}
          <Card.Text
            className="fs-4 p-1 bg-light text-uppercase m-0"
            style={{ padding: '0px', margin: '0px' }}>
            <b> {totalPricewithExtras} € </b>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default ItemCard;
