import Repository from "./Repository";

export default {
  
  payWaiterOrder(payload) {
    return Repository.post(`/order/waiter-print`, payload).then((res) => {
      return res;
    });
  },

  paySelectedWaiterOrder(payload) {
    return Repository.post(`/order/pay-selected-waiter`, payload).then((res) => {
      return res;
    });
  },

  getOrdersBySession(payload) {
    return Repository.get(
      `/order/session-orders/${payload.sessionId}?blId=${payload.businessLocationId}&flag=menuApp`
    ).then((res) => {
      return res;
    });
  },

  loadCourses() {
    return Repository.get(`/courses`).then((res) => {
      return res;
    });
  },
  getOrdersByCard(payload) {
    return Repository.get(
      `/order/getOrdersFromCardID/${payload.cardId}?blId=${payload.businessLocationId}`
    ).then((res) => {
      return res;
    });
  },
};
