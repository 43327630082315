import { Alert } from 'react-bootstrap';
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  UPDATE_CART,
  INCREASE_QUANTITY,
  DELETE_FROM_CART,
  POST_ORDER,
  GET_COURSES,
  ADD_INGREDIENTS,
  DELETE_INGREDIENTS,
  numberOfOrdersInCart,
  GET_CART_LENGTH,
  CHANGE_ING_STATUS,
  ADD_TO_TEMP_CART,
  UPDATE_CART_COURSE,
  GET_SESSION_CARD_ORDER_FAIL,
  GET_SESSION_CARD_ORDER_REQUEST,
  GET_SESSION_CARD_ORDER_SUCCESS,
  PAYMENT_REQUEST,
  PAYMENT_FAIL,
  PAYMENT_SUCCESS
} from '../types';

const initialState = {
  numberOfOrdersInCart: 0,
  courses: [],
  ordersPlaced: [],
  Carts: [],
  tempCart: [],
  orderLoading: false,
  orders: {},
  payWaiterLoader: false
};

export default function orderReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_INGREDIENTS: {
      //Get the id of element to find in cart array i.e cartData.id
      //User clicks on '+' in cart.js
      //It will dispatch an action named, 'ADD_INGREDIENT' with a payload as parameter
      //The payload will have 3 things, sku, quanity & id of element to find in Carts array(cartData.id)
      //Each subitem in a payload will have parent id with it
      //When in reducer, find parent id in the Carts array
      //Push the ingredientInfo object to subItems array of item with id of the found element in Carts array
      // console.log("ingredient payload in reucer", payload);
      console.log(payload);
      let parent = state.tempCart.find((item) => item.sku == payload.cartData.sku);
      let finalCart = state.Carts.find((item) => item.sku == payload.cartData.sku);
      let parentIndex = state.tempCart.findIndex((item) => item.sku == payload.cartData.sku);
      let finalCartIndex = state.Carts.findIndex((item) => item.sku == payload.cartData.sku);

      console.log(finalCartIndex, 'item foundhere ');
      // console.log('parent', parent);
      // if (parent != null && parent.subItems == null) {
      // 	parent = { ...parent, subItems: [] };
      // }

      if (finalCartIndex >= 0) {
        // console.log(
        //   "already in cart, adding ingredients",
        //   payload.cartData.subItems
        // );
        state.Carts[finalCartIndex].subItems = payload.cartData.subItems;
        console.log('updated item', state.Carts[finalCartIndex]);
      } else if (parent == null && finalCart == null) {
        let cart = {
          id: Number(payload.id),
          group: payload.cartData.group,
          quantity: 1,
          name: payload.cartData.name,
          image: payload.cartData.download_url,
          sku: payload.cartData.sku,
          course: payload.cartData.course,
          // name: payload.name,
          // image: payload.image,
          price: payload.cartData.price,
          subItems: payload.cartData.subItems
        };
        state.tempCart.push(cart);
      } else {
        state.tempCart[parentIndex].subItems = payload.cartData.subItems;
      }
      console.log(state.tempCart);
      // parent.subItems.push({
      // 	sku: payload.sku,
      // 	quantity: payload.quantity,
      // });

      return {
        ...state
      };
    }

    case DELETE_INGREDIENTS: {
      console.log(payload);
      console.log(state.tempCart);
      let parent = state.tempCart.find((item) => item.sku == payload.sku);
      let parentIndex = state.tempCart.findIndex((item) => item.sku == payload.sku);
      console.log(parent?.subItems?.filter((item) => item.sku != payload.subitemSku));
      // let subItem = parent?.subItems?.findIndex(
      // 	(item) => item.sku == payload.subitemSku
      // );
      // console.log(parentIndex);
      // console.log(state.tempCart[parentIndex]);
      state.tempCart[parentIndex].subItems = parent?.subItems?.filter(
        (item) => item.sku != payload.subitemSku
      );
      return {
        ...state
      };
    }

    case ADD_TO_CART: {
      // if (state.tempCart.length > 0) {
      //   state.Carts.push(state.tempCart);
      // }

      if (payload.temporary === true) {
        // console.log(
        //   'ttemp cart executing in erducer',
        //   payload.tempCart
        // );

        let test = state.tempCart.some(
          (itt) => itt.sku === payload.tempCart.sku && itt.name === payload.tempCart.name
        );

        // console.log("true or false" + state.Carts.some(item => item['id'] === payload.id))
        if (test) {
          // console.log('first if test here ' + test);
          let cart = {
            id: Number(payload.tempCart.id),
            group: payload.tempCart.group,
            quantity: 1,
            name: payload.tempCart.name,
            image: payload.tempCart.download_url,
            sku: payload.tempCart.sku,
            course: payload.tempCart.course,
            // name: payload.tempCart.name,
            // image: payload.tempCart.image,
            price: payload.tempCart.price,
            subItems: payload.tempCart.subItems
          };
          state.tempCart.push(cart);
        } else {
          // console.log('goinf with else 2nd ');
          let check = false;
          state.tempCart.map((item, key) => {
            if (Number(item.id) === Number(payload.tempCart.id)) {
              state.tempCart[key].quantity++;
              check = true;
            }
          });
          if (!check) {
            let tempSubItems = [];

            payload.tempCart.ingredients.map((itx) => {
              if (itx.selected === true) {
                return tempSubItems.push(itx);
              }

              return console.log('tempSubItems', tempSubItems);
            });

            // console.log('goinf with last if');
            let _cart = {
              id: Number(payload.tempCart.id),
              quantity: 1,
              name: payload.tempCart.name,
              group: payload.tempCart.group,
              image: payload.tempCart.download_url,
              sku: payload.tempCart.sku,
              course: payload.tempCart.course,
              // name: payload.tempCart.name,
              // image: payload.tempCart.image,
              price: payload.tempCart.price,
              subItems: tempSubItems
            };
            state.tempCart.push(_cart);
          }
        }
        // console.log('tempcart items:', state.tempCart);
        return {
          ...state,
          numberOfOrdersInCart: state.numberOfOrdersInCart + 1
        };
      } else {
        let test = state.Carts.some((itt) => itt.sku === payload.sku && itt.name === payload.name);

        if (state.tempCart.length > 0) {
          let test = state.Carts.some(
            (itt) => itt.sku === payload.sku && itt.name === payload.name
          );

          if (test !== true) {
            state.Carts.push(...state.tempCart);
            state.tempCart = [];
          }
        } else {
          let test = state.tempCart.some(
            (itt) => itt.sku === payload.tempCart.sku && itt.name === payload.tempCart.name
          );

          // console.log(
          //   'true or false' +
          //   state.Carts.some(
          //     (item) => item['id'] === payload.id
          //   )
          // );

          if (test === true) {
            // console.log('first if test hre ' + test);
            // console.log(
            //   'first if test hre payload' +
            //   JSON.stringify(payload)
            // );

            let addItem = state.Carts.findIndex(
              (item) => item.sku === payload.sku && item.name === payload.name
            );

            if (payload?.subItems?.length > 0) {
              // console.log(
              //   'pushing subItems to final cart',
              //   payload.subItems
              // );
              state.Carts[addItem].subItems.push(payload.subItems);
            }

            // console.log(
            //   'increasing quanitty of alreasyd existing item'
            // );
            state.Carts[addItem].quantity++;

            // console.log('after increment', state.Carts[addItem]);

            // let cart = {
            //   id: Number(payload.id),
            //   group: payload.group,
            //   quantity: 1,
            //   name: payload.name,
            //   image: payload.download_url,
            //   sku: payload.sku,
            //   // name: payload.name,
            //   // image: payload.image,
            //   price: payload.price,
            //   subItems: []
            // }
            // state.Carts.push(cart);
          } else {
            // console.log('goinf with else 2nd ');
            let check = false;
            state.Carts.map((item, key) => {
              if (Number(item.id) === Number(payload.id)) {
                state.Carts[key].quantity++;
                check = true;
              }
            });
            if (!check) {
              // console.log('goinf with last if');
              let _cart = {
                id: Number(payload.id),
                quantity: 1,
                name: payload.name,
                group: payload.group,
                image: payload.download_url,
                sku: payload.sku,
                course: payload.course,
                // name: payload.name,
                // image: payload.image,
                price: payload.price,
                subItems: []
              };
              state.Carts.push(_cart);
            }
          }
        }

        return {
          ...state,

          numberOfOrdersInCart: state.numberOfOrdersInCart + 1
        };
      }
    }
    case UPDATE_CART: {
      return {
        ...state,
        orders: payload
      };
    }
    case DELETE_FROM_CART: {
      //Find payload in cart
      let item_ = state.tempCart.find((item) => item.id === payload.id);
      //Find its quantity & store in quantity_

      // console.log("item found in cart", item_);
      //Minus the quantity of payload from number of orders

      console.log('delete reducer payload' + JSON.stringify(payload));

      if (payload.temporary === true) {
        return {
          ...state,
          numberOfOrdersInCart: state.Carts.length,
          tempCart: state.tempCart.filter((item) => {
            return item.id !== Number(payload.tempCart.id);
          })
        };
      } else {
        return {
          ...state,
          numberOfOrdersInCart: state.Carts.length,
          Carts: state.Carts.filter((item) => {
            return item.id !== Number(payload.id);
          })
        };
      }

      // let quantity_ = payload.quantity;
    }

    case GET_CART_LENGTH: {
      let numberCart = state.Carts.length;
      // state.numberOfOrdersInCart =  numberCart

      return {
        ...state,
        numberOfOrdersInCart: numberCart
      };
    }
    case INCREASE_QUANTITY: {
      // state.numberCart++
      let index = payload.temp;
      if (payload.temporary === true) {
        state.tempCart[index].quantity++;
      } else {
        state.Carts[payload].quantity++;
      }

      // if (testQuantity <= 0) {

      // }

      // if(!state.tempCart[payload].quantity){}

      // state.numberOfOrdersInCart++

      return {
        ...state
      };
    }
    case DECREASE_QUANTITY: {
      let index = payload.temp;

      if (payload.temporary === true) {
        let quantity = state.tempCart[index].quantity;
        if (quantity > 1) {
          // state.numberOfOrdersInCart--;
          state.tempCart[index].quantity--;
        }
      } else {
        let quantity = state.Carts[payload].quantity;
        if (quantity > 1) {
          // state.numberOfOrdersInCart--;
          state.Carts[payload].quantity--;
        }
      }

      return {
        ...state
      };
    }

    case UPDATE_CART_COURSE: {
      let index = state.Carts.findIndex((item) => {
        return item.sku === payload.sku;
      });
      state.Carts[index].course = payload.course;
      return {
        ...state
      };
    }
    // case PLACE_ORDER: {

    //   state.ordersPlaced = JSON.parse(JSON.stringify(state.Carts))

    //   return {
    //     ...state
    //   }
    // }

    case GET_COURSES: {
      // payload.map((cart, id) => {
      //   Object.defineProperty(cart, 'id', {
      //     value: id,
      //   });
      //   console.log("id added here", cart)
      // })

      return {
        ...state,
        courses: payload
      };
    }
    case GET_SESSION_CARD_ORDER_REQUEST: {
      return {
        ...state,
        orderLoading: true
      };
    }
    case GET_SESSION_CARD_ORDER_SUCCESS: {
      return {
        ...state,
        orders: payload,
        orderLoading: false
      };
    }
    case GET_SESSION_CARD_ORDER_FAIL: {
      return {
        ...state,
        orderLoading: false
      };
    }
    case PAYMENT_REQUEST: {
      return {
        ...state,
        payWaiterLoader: true
      };
    }
    case PAYMENT_SUCCESS: {
      return {
        ...state,
        payWaiterLoader: false
      };
    }
    case PAYMENT_FAIL: {
      return {
        ...state,
        payWaiterLoader: false
      };
    }

    case POST_ORDER: {
      function selectFewerProps(show) {
        const { quantity, sku } = show;
        return { quantity, sku };
      }

      const items = state.Carts.map(selectFewerProps);

      console.log('order api response:', payload);

      state.ordersPlaced.push(...state.Carts);

      console.log('new array' + JSON.stringify(state.ordersPlaced));

      state.Carts = [];

      state.numberOfOrdersInCart = 0;

      console.log('old array' + state.Carts);

      // window.location.replace('/bill')

      return {
        ...state
      };
    }

    default:
      return {
        ...state
      };
  }
}
