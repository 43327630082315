import {
  CHANGE_ING_STATUS,
  CREATE_TABLE,
  GET_ALLERGEN,
  GET_MENU,
  JOIN_TABLE,
  SET_CART_DATA,
  SET_SEARCH_RESULTS,
  SET_TABLE
} from '../types';

const initialState = {
  menu: [],
  allergens: [],
  table: null,
  loading: true,
  cartData: null,
  searchResults: []
};

export default function menuReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CART_DATA: {
      return {
        ...state,
        cartData: payload
      };
    }

    case SET_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: payload
      };
    }

    case CHANGE_ING_STATUS: {
      let temp_menu = JSON.parse(JSON.stringify(state.menu));

      let parent = temp_menu?.find((item) => item._id == payload?.id);
      let parentIndex = temp_menu?.findIndex((item) => item._id == payload?.id);

      parent.ingredients[payload.index].selected = payload.status;

      temp_menu[parentIndex] = parent;

      return {
        ...state,
        menu: temp_menu
      };
    }

    case GET_MENU: {
      let tempPayload = payload.map((item) => {
        return {
          ...item,
          ingredients: item.ingredients.map((itx) => {
            if (itx.type == 'Standard') {
              return { ...itx, selected: true };
            } else {
              return { ...itx, selected: false };
            }
          })
        };
      });

      return {
        ...state,
        loading: false,
        menu: tempPayload
      };
    }

    case GET_ALLERGEN: {
      return {
        ...state,
        allergens: payload
      };
    }

    case JOIN_TABLE: {
      return {
        ...state,
        table: payload
      };
    }

    case CREATE_TABLE: {
      return {
        ...state,
        table: payload
      };
    }

    case SET_TABLE: {
      console.log('setting table: ' + JSON.stringify(payload));

      return {
        ...state,
        table: payload
      };
    }

    default:
      return {
        ...state
      };
  }
}
