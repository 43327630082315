import React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Dailer from './dailer';
import './login.scss';
import { loginUser, Login } from '../../store/actions/user.Action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalInterface from '../core/ModalInterface';

const LoginPage = () => {
  const navigate = useNavigate();
  const [passcode, setPasscode] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();
  const onLoginHandler = async () => {
    setLoading(true);
    dispatch(loginUser(passcode))
      .then((user) => {
        setLoading(false);
        if (user.success) {
          dispatch(Login(user.data));
          navigate('/dashboard');
        } else {
          setShowModal(true);
          setErrorMsg('Error Occured');
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setShowModal(true);
        setErrorMsg('Error Occured ' + err?.response?.data?.message);
      });
  };
  return (
    <>
      <Container fluid className="bgColor-gray">
        <Row className="pb-5">
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="d-flex justify-content-center mt-5 logo">
              <div className="">
                <img src="img/logo.png" alt="logo" />
              </div>
            </div>
            <div className="d-flex justify-content-center pb-4 logo">
              <div className="">
                <img src="img/operate.png" alt="logo" />
              </div>
            </div>
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="d-flex justify-content-center">
              <Dailer
                onLoginHandler={onLoginHandler}
                loading={loading}
                passcode={passcode}
                setPasscode={setPasscode}
              />
            </div>
          </Col>
        </Row>

        <ModalInterface
          display={showModal}
          size="sm"
          footer={true}
          style={{ position: 'relative' }}
          handleClose={() => {
            setShowModal(false);
          }}
          bodyStyles={{ maxHeight: '100%', overflowY: 'scroll' }}
          content={<>{errorMsg}</>}
          cartFooter={<></>}
          className={'text-dark pt-2'}
          closeEvent={() => setShowModal(false)}
        />
      </Container>
    </>
  );
};

export default LoginPage;
