import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CountCard from '../../components/Session/CountCard';
import ScannedCardsCol from '../../components/Session/ScannedCardsCol';
import SessionUI from '../../components/Session/SessionUI';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
import { useDispatch, useSelector } from 'react-redux';
import { setTable, tableID, setCardIds, createSession } from '../../store/actions/tableActions';
import ModalInterface from '../../components/core/ModalInterface';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
const Registration = ({ setLocation }) => {
  const [cardData, setCardData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const loading = useSelector((state) => state.table.createSessionLoader);
  const UserRepository = RepositoryFactory.get('user');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pagerModal, setPagerModal] = useState(false);
  const [pagerNumber, setpagerNumber] = useState(null);
  useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  const handleRegister = () => {
 

    if (!cardData.length) {
      setShowModal(true);
      setErrorMsg('Please scane card first');
      return;
    }
    let table_no = '1';
    let busLocID = '771247277342722';
    let numberOfPeopl = 1;
    let obj = { table_no, busLocID, numberOfPeopl, cardData };
    if (pagerNumber) {
      obj['pagerNumber'] = pagerNumber;
    }
    // TODO have to add tableName table_no busLocID dynamically
    dispatch(createSession(obj)).then((resp) => {
      setpagerNumber(null);
      if (resp?.success) {
        setCardData([]);
        setShowModal(true);
        setErrorMsg('Session create successfully!');
        navigate('/process-done');
      } else {
        setShowModal(true);
        setErrorMsg('Error Occured' + resp?.data?.message);
      }
    });
  };
  const handleSubmit = () => {
    if (!pagerNumber) {
      setPagerModal(true);
      return;
    }
    handleRegister();
  };
  return (
    <>
      <SessionUI
        pageTitle={'EINCHECKEN'}
        cardData={cardData}
        setCardData={setCardData}
        imgCardColTitle={'KARTE SCANNEN'}
        BESTÄTIGEN={() => navigate('/process-done')}
        ABBRECHEN={() => navigate('/dashboard')}
        variant_ABBRECHEN={'vapiano-prim'}
        variant_BESTÄTIGEN={'vapiano-warn'}
        loading={loading}
        pagerNumber={pagerNumber}
        onClickFunc={handleSubmit}
        scannedCardCol={
          <ScannedCardsCol
            cardData={cardData}
            setCardData={setCardData}
            ScanCardsColTitle={'GESCANNTE KARTEN'}
            card_img_placeholder={'creditcard.png'}
            trashCan={true}
          />
        }
        thirdColCard={
          <CountCard
            ScanCardsColTitle={'  GESCANNTE PAGER'}
            countData={cardData.length}
            card_img_placeholder={'creditcard.png'}
            pagerNumber={pagerNumber}
            isPager={true}
            trashCan={true}
            setpagerNumber={setpagerNumber}
          />
        }
        cardImgSrc={'done-scan.png'}
      />
      <ModalInterface
        display={showModal}
        size="sm"
        footer={true}
        style={{ position: 'relative' }}
        handleClose={() => {
          setShowModal(false);
        }}
        bodyStyles={{ maxHeight: '100%', overflowY: 'scroll' }}
        content={<>{errorMsg}</>}
        cartFooter={<></>}
        className={'text-dark pt-2'}
        closeEvent={() => setShowModal(false)}
      />
      <ModalInterface
        display={pagerModal}
        size="lg"
        footer={true}
        style={{ position: 'relative' }}
        handleClose={() => {
          setPagerModal(false);
          setpagerNumber(null);
          // handleRegister()
        }}
        bodyStyles={{ maxHeight: '100%', overflowY: 'scroll' }}
        content={
          <>
            <Form.Control
              value={pagerNumber}
              onChange={(e) => {
                setpagerNumber(e.target.value);
              }}
              type="number"
              placeholder="Pager Number"
            />
          </>
        }
        footerContent={
          <>
            <Button
              onClick={() => {
                setPagerModal(false);
              }}
              style={{ backgroundColor: '#3498DB', border: 'none' }}
              type="submit">
              Submit
            </Button>
          </>
        }
        className={'text-dark pt-2'}
        closeEvent={() => setPagerModal(false)}
      />
    </>
  );
};

export default Registration;
