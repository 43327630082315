import axios from 'axios';
import OrderRepository from '../../repository/OrderRepository';
import {
  ADD_TO_CART,
  UPDATE_CART,
  DELETE_FROM_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  POST_ORDER,
  GET_COURSES,
  ADD_INGREDIENTS,
  DELETE_INGREDIENTS,
  GET_CART_LENGTH,
  ADD_TO_TEMP_CART,
  UPDATE_CART_COURSE,
  GET_SESSION_CARD_ORDER_REQUEST,
  GET_SESSION_CARD_ORDER_SUCCESS,
  GET_SESSION_CARD_ORDER_FAIL,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_FAIL
} from '../types';

export const AddIngredients = (payload) => {
  // console.log("ingredients payload", payload);

  return {
    type: ADD_INGREDIENTS,
    payload
  };
};

export const DeleteIngredients = (payload) => {
  // console.log("ingredients payload", payload);

  return {
    type: DELETE_INGREDIENTS,
    payload
  };
};

export const AddToCart = (payload) => {
  return {
    type: ADD_TO_CART,
    payload
  };
};

export const AddToTempCart = (payload) => {
  return {
    type: ADD_TO_TEMP_CART,
    payload
  };
};

export const UpdateCart = (payload) => {
  return {
    type: UPDATE_CART,
    payload
  };
};

export const DeleteFromCart = (payload) => {
  console.log('delete action here,', payload);
  return {
    type: DELETE_FROM_CART,
    payload
  };
};

export const IncreaseQuantity = (payload) => {
  return {
    type: INCREASE_QUANTITY,
    payload
  };
};

export const DecreaseQuantity = (payload) => {
  return {
    type: DECREASE_QUANTITY,
    payload
  };
};

export const UpdateCartCourse = (payload) => {
  return {
    type: UPDATE_CART_COURSE,
    payload
  };
};

export const GetCartLength = () => {
  return {
    type: GET_CART_LENGTH
  };
};

export const getCourses = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_BackendURL}/courses`);

    console.log(data);

    dispatch({
      type: GET_COURSES,
      payload: data.data
    });
  } catch (err) {
    alert('Something went wrong try again' + err);
  }
};

export const postOrder = (payload, Cart) => async (dispatch) => {
  console.log('post order executng: ', payload);

  function selectFewerProps(show) {
    const { quantity, sku, course, subItems, price } = show;
    const station = course;
    return { quantity, sku, station, subItems, price };
  }

  const items = Cart.map(selectFewerProps);

  payload.items = [...items];

  console.log('quanity & sku: ', items);
  console.log('quanity & sku in payload: ', payload);

  if (payload.items.length === 0 || !payload.tableNumber || !payload.businessLocationId) {
    alert('Make sure you have: \n 1- Add items to cart \n 2- Joined a table');
    return window.location.replace('/home');
  }

  try {
    const { res } = axios
      .post(`${process.env.REACT_APP_BackendURL}/order/order-dine-in`, payload)
      .then((res) => console.log(res));

    dispatch({
      type: POST_ORDER,
      payload: res
    });
  } catch (err) {
    alert('Something went wrong try again' + err);
  }
};
export const getcardOrders = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_SESSION_CARD_ORDER_REQUEST });
    let res = await axios
    .get(`${process.env.REACT_APP_BackendURL}/order/getOrdersFromCardID/${payload.cardId}?blId=${payload.businessLocationId}`)
    dispatch({
      type: GET_SESSION_CARD_ORDER_SUCCESS,
      payload: res?.data?.data[0]
    });
    return res?.data;
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_SESSION_CARD_ORDER_FAIL });
    return error.response;
  }
};
export const payWaiter = (payload) => async (dispatch) => {
  if (!payload.tableNumber || !payload.businessLocationId) {
    alert("Make sure you have: \n 1- Add items to cart \n 2- Joined a table");
    return window.location.replace("/home");
  }
  try {
    dispatch({ type: PAYMENT_REQUEST });
    let res = await axios
    .post(`${process.env.REACT_APP_BackendURL}/order/waiter-print`,payload)
    if (res?.data?.success) {
      dispatch({ type: PAYMENT_SUCCESS });
      alert("Paid Success Fully");
      return res;
    } else {
      alert("order failed");
      dispatch({ type: PAYMENT_FAIL, payload: res?.data?.data });
    }
  } catch (err) {
    alert("Order Failed:" + " " + err?.response?.data?.message);
    dispatch({ type: PAYMENT_FAIL, payload: err });
  }
};
export const payOrder = (payload) => async (dispatch) => {
  console.log(payload)
  if (!payload.tableInfo.tableNumber || !payload.tableInfo.businessLocationId) {
    alert("Make sure you have: \n 1- Add items to cart \n 2- Joined a table");
    return window.location.replace("/payment");
  }
  try {
    dispatch({ type: PAYMENT_REQUEST });
    let res = await axios
    .put(`${process.env.REACT_APP_BackendURL}/order/pay-selected`,payload)
    if (res?.data?.success) {
      dispatch({ type: PAYMENT_SUCCESS });
      alert("Paid Success Fully");
      return res;
    } else {
      alert("order failed");
      dispatch({ type: PAYMENT_FAIL, payload: res?.data?.data });
    }
  } catch (err) {
    alert("Order Failed:" + " " + err?.response?.data?.message);
    dispatch({ type: PAYMENT_FAIL, payload: err });
  }
};
export const loadOrdersBySession = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_SESSION_CARD_ORDER_REQUEST });
    let res = await axios
    .get(`${process.env.REACT_APP_BackendURL}/order/session-orders/${payload.sessionId}?blId=${payload.businessLocationId}&flag=menuApp`)
    dispatch({
      type: GET_SESSION_CARD_ORDER_SUCCESS,
      payload: res?.data?.data[0]
    });
    return res?.data;
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_SESSION_CARD_ORDER_FAIL });
    return error.response;
  }
};
// export const updateOrder =  (payload) => {

//   return {
//     type: PLACE_ORDER,
//     payload
//   }
// }
