// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_TEMP_CART = 'ADD_TO_TEMP_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const numberOfOrdersInCart = 'numberOfOrdersInCart';
export const ORDER_INFO = 'ORDER_INFO';
export const POST_ORDER = 'POST_ORDER';
export const GET_COURSES = 'GET_COURSES';
export const ADD_INGREDIENTS = 'ADD_INGREDIENTS';
export const DELETE_INGREDIENTS = 'DELETE_INGREDIENTS';
export const GET_CART_LENGTH = 'GET_CART_LENGTH';
export const CHANGE_ING_STATUS = 'CHANGE_ING_STATUS';
export const UPDATE_CART_COURSE = 'UPDATE_CART_COURSE';

//Menu
export const GET_MENU = 'GET_MENU';
export const GET_ALLERGEN = 'GET_ALLERGEN';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_CART_DATA = 'SET_CART_DATA';
// Special
export const GET_SPECIAL = 'GET_SPECIAL';
export const SPECIALS_FAIL = 'SPECAILS_FAIL';

//Table
export const JOIN_TABLE = 'JOIN_TABLE';
export const CREATE_TABLE = 'CREATE_TABLE';
export const SET_TABLE = 'SET_TABLE';
export const BUS_INFO = 'BUS_INFO';
export const SESSION_ID = 'SESSION_ID';
export const CARDS_IDS = 'CARDS_IDS';
export const GET_EXSISTINGSESSION_REQUEST = 'GET_EXSISTINGSESSION_REQUEST';
export const GET_EXSISTINGSESSION_SUCCESS = 'GET_EXSISTINGSESSION_SUCCESS';
export const GET_EXSISTINGSESSION_FAIL = 'GET_EXSISTINGSESSION_FAIL';
export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAIL = 'CREATE_SESSION_FAIL';
export const UPDATE_SESSION_CARD_REQUEST = 'CREATE_SESSION_REQUEST';
export const UPDATE_SESSION_CARD_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const UPDATE_SESSION_CARD_FAIL = 'CREATE_SESSION_FAIL';
export const GET_SESSION_CARD_ORDER_REQUEST = 'GET_SESSION_CARD_ORDER_REQUEST';
export const GET_SESSION_CARD_ORDER_SUCCESS = 'GET_SESSION_CARD_ORDER_SUCCESS';
export const GET_SESSION_CARD_ORDER_FAIL = 'GET_SESSION_CARD_ORDER_FAIL';
export const MERGE_SESSIONS_REQUEST = 'MERGE_SESSIONS_REQUEST';
export const MERGE_SESSIONS_SUCCESS = 'MERGE_SESSIONS_SUCCESS';
export const MERGE_SESSIONS_FAIL = 'MERGE_SESSIONS_FAIL';
export const SAVE_SCANED_CARD_ID = 'SAVE_SCANED_CARD_ID';
export const UPDATE_SESSION_PAGER_REQUEST = 'UPDATE_SESSION_PAGER_REQUEST';
export const UPDATE_SESSION_PAGER_SUCCESS = 'UPDATE_SESSION_PAGER_SUCCESS';
export const UPDATE_SESSION_PAGER_FAIL = 'UPDATE_SESSION_PAGER_FAIL';

// GuestBook Canvas
export const POST_CANVAS = 'POST_CANVAS';
export const GRID_CANVAS = 'GRID_CANVAS';

// set promotion
export const GET_PROMOTION_SUCCESS = 'GET_PROMOTION_SUCCESS';
export const GET_PROMOTION_FAIL = 'GET_PROMOTION_FAIL';
// get best seller
export const GET_BEST_SELLER_SUCCESS = 'GET_BEST_SELLER_SUCCESS';
export const GET_BEST_SELLER_FAIL = 'GET_BEST_SELLER_FAIL';
//user
export const LOGIN = 'LOGIN';
//order
export const PAYMENT_REQUEST="PAYMENT_REQUEST"
export const PAYMENT_SUCCESS="PAYMENT_SUCCESS"
export const PAYMENT_FAIL="PAYMENT_FAIL"