import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, ListGroup } from 'react-bootstrap';
import './sessionUI.scss';

const ScannedCardsCol = ({
  ScanCardsColTitle,
  card_img_placeholder,
  cardData,
  trashCan,
  setCardData,
  alreadyExsistingCardsOfSession
}) => {
  const handleDelete = (index, itemId) => {
    setCardData([...cardData.slice(0, index), ...cardData.slice(index + 1)]);
  };

  return (
    <Col className="bg-card rounded" xs={12} sm={12} md={3}>
      <Card className="rounded pt-2 pb-3 card-list">
        <Card.Body className="d-flex flex-column gap-3 h-100 ">
          <div className="d-flex align-items-center justify-content-between card-head">
            <Card.Title>{ScanCardsColTitle}</Card.Title>
            <Card.Img
              variant="top"
              src={`img/${card_img_placeholder}`}
              className="img-card-title img-responsive"
              width={50}
            />
          </div>
          <Card.Text className="m-0 card-content">
            <ListGroup variant="" className="bg-white ">
              {alreadyExsistingCardsOfSession &&
                alreadyExsistingCardsOfSession?.map((id, index) => (
                  <>
                    <ListGroup.Item
                      className="border-bottom px-2 d-flex justify-content-between fs-5 align-items-baseline h-100"
                      key={index}
                    >
                      <span className="">{id}</span>{' '}
                    </ListGroup.Item>
                    <div className="d-flex justify-content-center">
                      <div className="black-line"></div>
                    </div>
                  </>
                ))}
              {cardData?.map((id, index) => (
                <>
                  <ListGroup.Item
                    className="border-bottom px-2 d-flex justify-content-between fs-5 align-items-baseline h-100"
                    key={index}
                  >
                    <span className="">{id}</span>{' '}
                    {trashCan === true ? (
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        onClick={(event) => {
                          handleDelete(index, id);
                        }}
                        className="pointer-hand"
                      />
                    ) : null}
                  </ListGroup.Item>
                  <div className="d-flex justify-content-center">
                    <div className="black-line"></div>
                  </div>
                </>
              ))}
            </ListGroup>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ScannedCardsCol;
