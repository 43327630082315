import { LOGIN } from '../types';
const initialStata = {
  user: {}
};
export default function bestSellerReducer(state = initialStata, { type, payload }) {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        user: payload
      };
    default:
      return {
        ...state
      };
  }
}
