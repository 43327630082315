import { POST_CANVAS, GRID_CANVAS } from '../types';

const initialState = {
  canvasPost: undefined,
  canvasGrid: [],
  loading: true
};

export default function canvasReducer(state = initialState, { type, payload }) {
  switch (type) {
    case POST_CANVAS: {
      console.log('canvas post response in reducer', payload);

      return {
        ...state,
        canvasPost: payload
      };
    }
    case GRID_CANVAS: {
      return {
        ...state,
        canvasGrid: payload
      };
    }
    default:
      return {
        ...state
      };
  }
}
