import {
  SET_TABLE,
  BUS_INFO,
  ORDER_INFO,
  CARDS_IDS,
  SESSION_ID,
  GET_EXSISTINGSESSION_REQUEST,
  GET_EXSISTINGSESSION_SUCCESS,
  GET_EXSISTINGSESSION_FAIL,
  CREATE_SESSION_FAIL,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_REQUEST,
  UPDATE_SESSION_CARD_REQUEST,
  UPDATE_SESSION_CARD_SUCCESS,
  UPDATE_SESSION_CARD_FAIL,
  MERGE_SESSIONS_REQUEST,
  MERGE_SESSIONS_SUCCESS,
  MERGE_SESSIONS_FAIL,
  SAVE_SCANED_CARD_ID,
  UPDATE_SESSION_PAGER_REQUEST,
  UPDATE_SESSION_PAGER_SUCCESS,
  UPDATE_SESSION_PAGER_FAIL
} from '../types';

const initialState = {
  table: '',
  cardIds: [],
  sessionId: '',
  orderInfo: {},
  business_Info: { business_name: '', table_sitting_amount: 0 },
  scanedSession: {},
  getExsistingSessionLoader: false,
  createSessionLoader: false,
  updateSessionLoader: false,
  mergeSessionsLoader:false,
  scanedCard:""
};

export default function tableReducer(state = initialState, { type, payload }) {
  switch (type) {
    case BUS_INFO: {
      console.log('busData response from action', payload);
      console.log('busInfo ', state.business_Info);
      return {
        ...state,
        business_Info: payload.data
      };
    }

    case SET_TABLE: {
      console.log('setting table: ' + JSON.stringify(payload));

      return {
        ...state,
        table: payload
      };
    }
    case CARDS_IDS: {
      console.log('cards', payload);
      return {
        ...state,
        cardIds: payload
      };
    }
    case SESSION_ID: {
      return {
        ...state,
        sessionId: payload
      };
    }
    case ORDER_INFO: {
      console.log('setting table: ' + JSON.stringify(payload));

      return {
        ...state,
        orderInfo: payload
      };
    }
    case GET_EXSISTINGSESSION_REQUEST: {
      return {
        ...state,
        getExsistingSessionLoader: true
      };
    }
    case GET_EXSISTINGSESSION_SUCCESS: {
      return {
        ...state,
        scanedSession: payload,
        getExsistingSessionLoader: false
      };
    }
    case SAVE_SCANED_CARD_ID: {
      return {
        ...state,
        scanedCard:payload
      };
    }
    
    case GET_EXSISTINGSESSION_FAIL: {
      return {
        ...state,
        getExsistingSessionLoader: false
      };
    }
    case CREATE_SESSION_REQUEST: {
      return {
        ...state,
        createSessionLoader: true
      };
    }
    case CREATE_SESSION_SUCCESS: {
      return {
        ...state,
        scanedSession: payload,
        createSessionLoader: false
      };
    }
    case CREATE_SESSION_FAIL: {
      return {
        ...state,
        createSessionLoader: false
      };
    }
    case UPDATE_SESSION_CARD_REQUEST: {
      return {
        ...state,
        updateSessionLoader: true
      };
    }
    case UPDATE_SESSION_CARD_SUCCESS: {
      return {
        ...state,
        scanedSession: payload,
        updateSessionLoader: false
      };
    }
    case UPDATE_SESSION_CARD_FAIL: {
      return {
        ...state,
        updateSessionLoader: false
      };
    }
    case MERGE_SESSIONS_REQUEST: {
      return {
        ...state,
        mergeSessionsLoader: true
      };
    }
    case MERGE_SESSIONS_SUCCESS: {
      return {
        ...state,
        mergeSessionsLoader: false
      };
    }
    case MERGE_SESSIONS_FAIL: {
      return {
        ...state,
        mergeSessionsLoader: false
      };
    }
    case UPDATE_SESSION_PAGER_REQUEST: {
      return {
        ...state,
        updateSessionLoader: true
      };
    }
    case UPDATE_SESSION_PAGER_SUCCESS: {
      return {
        ...state,
        scanedSession: payload,
        updateSessionLoader: false
      };
    }
    case UPDATE_SESSION_PAGER_FAIL: {
      return {
        ...state,
        updateSessionLoader: false
      };
    }
    default:
      return {
        ...state
      };
  }
}
