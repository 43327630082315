import { useNavigate } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import './searchBar.scss';
import { Login } from '../../../store/actions/user.Action';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const SearchBar = ({ setLocation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const signOut = (event) => {
    dispatch(Login({}));
    navigate('/');
  };

  // Define state to hold the current time
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update the time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Format the time as a string
  const timeString = currentTime.toLocaleTimeString();

  return (
    <>
      <div className="main-nav">
        <Navbar className={'searchbar-nav bg-vapiano-prim text-white'}>
          <Nav.Item>
            <h6 className="mb-0 fs-6">{user?.user_type}</h6>{' '}
          </Nav.Item>

          <Nav.Item>
            <span className="fs-5 ps-5">{timeString}</span>
          </Nav.Item>
          <Nav.Item>
            <span className="fs-6">
              {' '}
              {/* {'3001 | DANIELE ELIA'}{' '} */}
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="pe-2 ps-3 fs-5"
                onClick={(e) => signOut(e)}
                role="button"
              />
            </span>
          </Nav.Item>
        </Navbar>
      </div>
    </>
  );
};

export default SearchBar;
