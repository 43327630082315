import axios from 'axios';
import { LOGIN } from '../types';
const baseDomain = process.env.REACT_APP_BackendURL;
const baseURL = `${baseDomain}`;

const axiosObj = axios.create({
  baseURL
});
export const loginUser = (id) => async (dispatch) => {
  try {
    return axiosObj.get(`/user-detail/${id}`).then((res) => {
      return res.data;
    });
  } catch (err) {
    alert('Something went wrong try again' + err);
  }
};

export const Login = (payload) => {
  return {
    type: LOGIN,
    payload
  };
};
