// Root Reducer
import { combineReducers } from 'redux';
import canvasReducer from './canvasReducer';
import menuReducer from './menuReducer';
import orderReducer from './orderReducer';
import tableReducer from './tableReducer';
import specialReducer from './specialReducer';
import promotionReducer from './promotionReducer';
import bestSellerReducer from './bestSellerReducer';
import userReduser from './userReducer';

export let rootReducer = combineReducers({
  order: orderReducer,
  menu: menuReducer,
  canvas: canvasReducer,
  table: tableReducer,
  special: specialReducer,
  promotions: promotionReducer,
  bestSeller: bestSellerReducer,
  user: userReduser
});

export default rootReducer;
