import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import SearchBar from '../components/home/SearchBar/SearchBar';

import Registration from '../views/Session/Registration';

import Login from '../views/Login/Login';
import ExpandGroup from '../views/Session/ExpandGroup';
import ExitBtn from '../common/ExitBtn';
import Footer from '../common/Footer';
import ProcessDone from '../views/ProcessDone/ProcessDone';
import GruppeErweiternProcessDone from '../views/GruppProces/GruppeErweiternProcessDone';
import Payment from '../views/Payment/Payment';
import Dashboard from '../views/Dashboard/Dashbord';

import './App.scss';
import SessionOrders from '../views/Orders/SessionOrders';
import SessionNewOrder from '../views/Session/SessionNewOrder';
import ProtectedRoute from './ProtectedRoutes';
function App() {
  const [location, setLocation] = React.useState(window.location.pathname);

  const showNavBar = () => {
    if (location !== '/') {
      return <SearchBar setLocation={setLocation} />;
    } else {
      return null;
    }
  };

  const showFooter = () => {
    if (location === '/') {
      return null;
    } else {
      return <Footer />;
    }
  };
  const ShowExitBtn = () => {
    if (location === '/' || location === '/login' || location === '/dashboard') {
      return '';
    } else {
      return <ExitBtn />;
    }
  };

  return (
    <Container fluid className="App p-0 m-0">
      {showNavBar()}

      {ShowExitBtn()}
      {/* {(location === "/login" ? "": <ExitBtn />)} */}
      <Routes>
        <Route
          path="/register"
          element={
            <ProtectedRoute>
              <Registration setLocation={setLocation} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/expand-group"
          element={
            <ProtectedRoute>
              <ExpandGroup setLocation={setLocation} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoute>
              <Payment setLocation={setLocation} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/process-done"
          element={
            <ProtectedRoute>
              <ProcessDone setLocation={setLocation} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gruppe-process-done"
          element={
            <ProtectedRoute>
              <GruppeErweiternProcessDone setLocation={setLocation} />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard setLocation={setLocation} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/session-orders"
          element={
            <ProtectedRoute>
              <SessionOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new-session-orders"
          element={
            <ProtectedRoute>
              <SessionNewOrder />
            </ProtectedRoute>
          }
        />
      </Routes>
      {showFooter()}
    </Container>
  );
}

export default App;
