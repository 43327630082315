import axios from 'axios';

const baseDomain = process.env.REACT_APP_BackendURL;
const baseURL = `${baseDomain}`;

const axiosObj = axios.create({
  baseURL
});

export default {
  checkUser(tableName, table_no, buid) {
    return axiosObj
      .post(`/table-sessions/check`, {
        table_name: tableName,
        businessLocationId: buid,
        table_id: table_no
      })
      .then((res) => {
        return res.data;
      });
  },
  createTable({table_no, busLocID, numberOfPeopl, cardData,pagerNumber}) {
    let obj = {
      businessLocationId: busLocID,
      table_id: table_no,
      space_used: numberOfPeopl
    };
    if (cardData.length) {
      obj.cards = cardData;
    }
    if(pagerNumber){
      obj.pagerNumber = pagerNumber;
    }
    console.log(obj)
    return axiosObj.post(`/table-sessions/create-table`, obj).then((res) => {
      return res.data;
    });
  },
  addCardToExsistingSession({sessionId, busLocID, cardData}) {
    return axiosObj
      .post(`/table-sessions/updateSessionCard`, {
        table_id: sessionId,
        businessLocationId: busLocID,
        cardData
      })
      .then((res) => {
        return res.data;
      });
  },
  scaneCardToGetSession(id) {
    return axiosObj.get(`/table-sessions/scane-session/${id}`).then((res) => {
      return res.data;
    });
  },
  mergeSessions(payload) {
    return axiosObj.post(`/table-sessions/merge-session-by-card`,payload).then((res) => {
      return res;
    });
  },
  updateSessionPager({sessionId, pagerNumber}) {
    return axiosObj
      .put(`/table-sessions/update-session-pager`, {
        id: sessionId,
        pagerNumber
      })
      .then((res) => {
        return res.data;
      });
  },
};
