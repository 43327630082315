import React from 'react';

const ActionBtn = ({ passcode, loading, onClick, action,isPayment }) => {
  return (
    <>
      <div className="action-wrapper">
        <button
          disabled={isPayment? (passcode?.length < 1 ? true : false): action === 'enter' ? (passcode?.length !== 4 ? true : false) : false}
          className="action-btn"
          onClick={onClick}
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <img
              src={action === 'back' ? 'img/backspace.png' : 'img/enter.png'}
              height={30}
              alt=""
            />
          )}
        </button>
      </div>
    </>
  );
};

export default ActionBtn;
