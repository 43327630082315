import React from 'react';
import LoginPage from '../../components/login/loginPage';

const Login = () => {
  return (
    <>
      <LoginPage />
    </>
  );
};
export default Login;
