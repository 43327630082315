import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './dashboard.scss';

const Dashboard = ({ setLocation }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setLocation(window.location.pathname);
  }, []);

  return (
    <>
      <Container fluid className="dashboard-div">
        <Row className="dashboard-wrapper d-flex align-items-center">
          <Col lg="12" md="12" sm="12" xs="12">
            <Row className="d-flex align-items-center justify-content-center p-3">
              <Col lg="3" md="3" sm="6" xs="6">
                <div className="d-flex justify-content-center text-center">
                  <div
                    className="btn-menu bg-light-blue d-flex justify-content-center align-items-center"
                    role={'button'}
                    onClick={() => {
                      navigate('/payment');
                    }}
                  >
                    <img src="img/menu/Wallet.png" alt="" />
                  </div>
                </div>
                <label role={'button'}>KASSIEREN</label>
              </Col>
              <Col lg="3" md="3" sm="6" xs="6">
                <div className="d-flex justify-content-center text-center">
                  <div
                    className="btn-menu bg-orange  d-flex justify-content-center align-items-center"
                    role={'button'}
                    onClick={() => {
                      navigate('/register');
                    }}
                  >
                    <img src="img/menu/addperson.png" alt="" />
                  </div>
                </div>
                <label role={'button'}>EINCHECKEN</label>
              </Col>
              <Col lg="3" md="3" sm="6" xs="6">
                <div className="d-flex justify-content-center text-center">
                  <div
                    className="btn-menu bg-red d-flex justify-content-center align-items-center"
                    role={'button'}
                    onClick={() => {
                      navigate('/expand-group');
                    }}
                  >
                    <img src="img/menu/addgroup.png" alt="" />
                  </div>
                </div>
                <label role={'button'}>GRUPPE ERWEITERN</label>
              </Col>
              <Col lg="3" md="3" sm="6" xs="6">
                <div className="d-flex justify-content-center text-center">
                  <div
                    className="btn-menu bg-purple d-flex justify-content-center align-items-center"
                    role={'button'}
                  >
                    <img src="img/menu/List.png" className="list-img" alt="" />
                  </div>
                </div>
                <label role={'button'}>GÄSTE LISTE</label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
