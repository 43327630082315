import { GET_PROMOTION_SUCCESS, GET_PROMOTION_FAIL } from '../types';
const initialStata = {
  loading: false,
  promotion: []
};
export default function promotionReducer(state = initialStata, { type, payload }) {
  switch (type) {
    case GET_PROMOTION_SUCCESS:
      return {
        ...state,

        promotion: payload
      };
    case GET_PROMOTION_FAIL:
      return {
        ...state,
        error: payload
      };
    default:
      return {
        ...state
      };
  }
}
