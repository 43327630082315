import React from 'react';

const BtnLogin = ({ onClick, nbr,classname }) => {
  return (
    <>
      <div>
        <button onClick={onClick} className={`digit-btn ${classname}`} value={nbr}>
          {nbr}
        </button>
      </div>
    </>
  );
};

export default BtnLogin;
