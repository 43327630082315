import React, { useCallback, useRef } from 'react';
import ModalInterface from '../../components/core/ModalInterface';
import Scanner from './Scanner/Scanner';
import './style.css';
const Scan = ({
  setShowModal,
  setErrorMsg,
  isFirstScane,
  setIsFirstScane,
  setFirstScaneCard,
  setCardData,
  cardData,
  actionsValue,
  isPayment,
  alreadyExsistingCardsOfSession,
  setMergeCardId
}) => {
  const useEffectOnce = (callback, when) => {
    const hasRunOnce = React.useRef(false);
    React.useEffect(() => {
      if (when && !hasRunOnce.current) {
        callback();
        hasRunOnce.current = true;
      }
    }, [when]);
  };
  const { actions, onHandleAction } = actionsValue;
  const inputRef = useRef(null);
  const abortController = new AbortController();
  abortController.signal.onabort = () => {
    // All NFC operations have been aborted.
  };

  const scan = useCallback(async () => {
    inputRef.current.focus();
  }, []);
  const onReading = (serialNumber) => {
    if (isFirstScane ) {
      setFirstScaneCard(serialNumber);
      onHandleAction({ scan: null });
      // abortController.abort();
      return;
    } else {
      if (
        alreadyExsistingCardsOfSession?.includes(serialNumber) ||
        cardData?.includes(serialNumber)
      ) {
        setShowModal(true);
        setErrorMsg('This card already exsists for this session');
        onHandleAction({ scan: null });
        // abortController.abort();
        return;
      } else {
        onHandleAction({ scan: null });
        if(isPayment){
          setMergeCardId(serialNumber)
        }else{
          setCardData((oldArray) => [...oldArray, serialNumber]);
        }
      }
    }
  };
  useEffectOnce(() => {
    scan();
  }, scan);
  function convertNfcUid(nfcUid) {
    // Remove any colons or spaces in the UID
    nfcUid = nfcUid.replace(/:|\s/g, '');
    // Pad the UID with zeros if it is less than 6 characters long
    // nfcUid = nfcUid.padStart(6, '0');
    return nfcUid.slice(nfcUid.length - 14);
  }
  const cardIdHandler = (e) => {
     setTimeout(()=>{
    inputRef.current.blur();
    const convertedUid = convertNfcUid(e.target.value);
    onReading(convertedUid.toLowerCase());
    },1000)  
  };
  return (
    <>
      {/* {actions.scan === 'scanning' &&
                <Scanner status={actions.scan} actionsValue={actionsValue}></Scanner>
            } */}
      <ModalInterface
        display={actions.scan === 'scanning'}
        center="true"
        onClick={() => {
          onHandleAction({ scan: null });
        }}
        handleClose={() => {
          onHandleAction({ scan: null });
        }}
        closeEvent={() => onHandleAction({ scan: null })}
        size="sm"
        content={
          <>
            <Scanner status={actions.scan} actionsValue={actionsValue}></Scanner>
            <input
              style={{}}
              onChange={cardIdHandler}
              type="text"
              className="nfc_uid_field"
              ref={inputRef}
            />
          </>
        }
      />
    </>
  );
};

export default Scan;
