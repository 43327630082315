// import { useNavigate } from "react-router-dom";
import { Nav, Navbar } from 'react-bootstrap';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import './infoBar.scss';

const InfoBar = () => {
  // const history = useNavigate();
  // const signOut = (event) => {
  //   console.log(event);
  //   history("/dashboard");
  // };

  return (
    <>
      <Navbar className={'ps-2 py-0 justify-content-between main-nav '}>
        <Nav.Item>
          <h6 className="mb-0 fs-5">ID 451023</h6>{' '}
        </Nav.Item>

        <Nav.Item>
          <h6 className="mb-0 fs-5"> {'13:45'}</h6>
        </Nav.Item>
        <Nav.Item>
          <span className="fs-5"> </span>
          {/* <h6 className="mb-0 fs-5">
            BEZHALT <b>0/5</b>
          </h6> */}
        </Nav.Item>
        {/* <Nav.Item className="text-white bg-vapiano-prim"> */}
        {/* <span className="fs-4 bg-vapiano-prim text-white align-self-stretch">ALLE AUSWÄHLEN</span> */}
        {/* </Nav.Item> */}
      </Navbar>
    </>
  );
};

export default InfoBar;
