import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
const ProtectedRoutes = ({ children }) => {
  const user = useSelector((store) => store.user.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (!loading && Object.keys(user).length === 0) {
    return <Navigate to="/" replace />;
  }

  return <>{<div>{children}</div>}</>;
};

export default ProtectedRoutes;
