import axios from 'axios';
import UserRepository from '../../repository/UserRepository';
import {
  SET_TABLE,
  BUS_INFO,
  ORDER_INFO,
  CARDS_IDS,
  SESSION_ID,
  GET_EXSISTINGSESSION_REQUEST,
  GET_EXSISTINGSESSION_SUCCESS,
  GET_EXSISTINGSESSION_FAIL,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAIL,
  UPDATE_SESSION_CARD_SUCCESS,
  UPDATE_SESSION_CARD_FAIL,
  UPDATE_SESSION_CARD_REQUEST,
  MERGE_SESSIONS_REQUEST,
  MERGE_SESSIONS_SUCCESS,
  MERGE_SESSIONS_FAIL,
  SAVE_SCANED_CARD_ID,
  UPDATE_SESSION_PAGER_REQUEST,
  UPDATE_SESSION_PAGER_SUCCESS,
  UPDATE_SESSION_PAGER_FAIL
} from '../types';

export const getBusinessInfo = (payload) => async (dispatch) => {
  try {
    let { data } = await axios.post(`${process.env.REACT_APP_BackendURL}/business`, payload);

    dispatch({
      type: BUS_INFO,
      payload: data
    });
  } catch (err) {
    alert('Something went wrong try again' + err);
  }
};

export const orderInfo = (payload) => {
  return {
    type: ORDER_INFO,
    payload
  };
};

export const setTable = (payload) => {
  return {
    type: SET_TABLE,
    payload
  };
};
export const tableID = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SESSION_ID,
      payload
    });
  } catch (error) {
    alert('Something went wrong with your Session. n/ Please reload & try again:' + error);
  }
};
export const setCardIds = (payload) => {
  return {
    type: CARDS_IDS,
    payload
  };
};
export const getExsistingSesion = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_EXSISTINGSESSION_REQUEST });
    let res = await UserRepository.scaneCardToGetSession(id);
    dispatch({
      type: GET_EXSISTINGSESSION_SUCCESS,
      payload: res?.data[0]
    });
    dispatch({
      type: SAVE_SCANED_CARD_ID,
      payload: id
    });
    return res;
  } catch (error) {
    console.log(error.response);
    dispatch({ type: GET_EXSISTINGSESSION_FAIL });
    return error.response;
  }
};
export const createSession = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_SESSION_REQUEST });
    let res = await UserRepository.createTable(payload);
    dispatch({
      type: CREATE_SESSION_SUCCESS,
      payload: res?.data[0]
    });
    return res;
  } catch (error) {
    console.log(error);
    dispatch({ type: CREATE_SESSION_FAIL });
    return error.response;
  }
};
export const updaterSessionCards = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SESSION_CARD_REQUEST });
    let res = await UserRepository.addCardToExsistingSession(payload);
    dispatch({
      type: UPDATE_SESSION_CARD_SUCCESS,
      payload: res?.data[0]
    });
    return res;
  } catch (error) {
    console.log(error);
    dispatch({ type: UPDATE_SESSION_CARD_FAIL });
    return error.response;
  }
};
export const mergeSessionsThroughCards = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MERGE_SESSIONS_REQUEST });
    let res = await  UserRepository.mergeSessions(payload);
    dispatch({ type: MERGE_SESSIONS_SUCCESS });
    console.log(res)
    return res?.data;
  } catch (error) {
    console.log(error);
    dispatch({ type: MERGE_SESSIONS_FAIL });
    return error.response;
  }
};
export const updaterSessionPager = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SESSION_PAGER_REQUEST });
    let res = await UserRepository.updateSessionPager(payload);
    console.log(res)
    dispatch({
      type: UPDATE_SESSION_PAGER_SUCCESS,
      payload: res?.data
    });
    return res;
  } catch (error) {
    console.log(error);
    dispatch({ type: UPDATE_SESSION_PAGER_FAIL });
    return error.response;
  }
};
