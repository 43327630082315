import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PaySelected from '../../components/modal/payselected';
import InfoBar from '../../components/orders/InfoBar';
import ItemCard from '../../components/orders/ItemCard';
import { payWaiter } from '../../store/actions/orderAction';
import { v4 as uuidv4 } from 'uuid';
// import { saleEntries } from '../Orders/mockdata';
import './newsession.scss';
const SessionNewOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedOrders, SetSelectedOrders] = useState([]);
  const order = useSelector((state) => state.order.orders);
  const loader = useSelector((state) => state.order.payWaiterLoader);
  const scanedCard = useSelector((state) => state.table.scanedCard);
  const [isPaySelected, setIsPaySelected] = useState(false);
  const [paySelectedTotal, setPaySelectedTotal] = useState(0);
  const [paySelectedOrder, setpaySelectedOrder] = useState({});
  const [filterdfData, setFilterdData] = useState([]);
  useEffect(() => {
    if (order.payment_status === 'paid') {
      navigate('/payment');
      return;
    }
    if (order) {
      let filterdArrayData = {};
      order?.salesEntries?.forEach((_item) => {
        if (filterdArrayData[_item.cardId]) {
          filterdArrayData[_item.cardId].push(_item);
        } else {
          filterdArrayData[_item.cardId] = [_item];
        }
      });
      setFilterdData(filterdArrayData);
      const totalPrice = order.salesEntries?.reduce((acc, order) => {
        const subLineItemPrice = order?.subLineItems?.reduce(
          (subAcc, subItem) => subAcc + subItem.itemPrice,
          0
        );
        return acc + order.itemPrice + subLineItemPrice;
      }, 0);
      setTotal(Number(Number(totalPrice).toFixed(2)));
    }
  }, [order]);

  const notifyKitchen = (finalBill) => {
    let msgDetails = `${new Date()} | Pay to Waiter | Table Name: ${
      order?.tableNumber
    } | Order Total: €${finalBill?.toFixed(2)}`; //Tip Amount: €${(total - subAmount).toFixed(2)
    msgDetails = msgDetails + `| Table No: ${order?.tableNumber}`;
    return msgDetails;
  };
  const payToWaiter = () => {
    let finalBill = Number(total);
    if (finalBill > 0) {
      if (order?.payment_status !== 'paid') {
        const waiterPayload = {
          businessLocationId: String(order?.businessLocationId),
          thirdPartyReference: uuidv4(),
          orderType: 'served',
          sessionID: order?.sessionId,
          tableNumber: Number(order?.tableNumber),
          orderNote: notifyKitchen(finalBill),
          totalAmount: finalBill,
          salesEntries: order?.salesEntries?.filter((_item) => _item.payment_status !== 'paid')
        };
        dispatch(payWaiter(waiterPayload)).then((res) => {
          if (res) {
            navigate('/dashboard');
          }
        });
      } else {
        alert('Oops..! This Order has been paid.');
      }
    }
  };
  const payToWaiterSelected = () => {
    if (!selectedOrders.length) {
      alert('Plz Select Items');
      return;
    }
    const totalSelectedPayWaiterPrice = selectedOrders?.reduce((acc, order) => {
      const subLineItemPrice = order?.subLineItems?.reduce(
        (subAcc, subItem) => subAcc + subItem.itemPrice,
        0
      );
      return acc + order.itemPrice + subLineItemPrice;
    }, 0);
    let finalBill = Number(Number(totalSelectedPayWaiterPrice).toFixed(2));
    if (finalBill > 0) {
      if (order?.payment_status !== 'paid') {
        const waiterPayload = {
          businessLocationId: String(order?.businessLocationId),
          thirdPartyReference: uuidv4(),
          orderType: 'served',
          sessionID: order?.sessionId,
          tableNumber: Number(order?.tableNumber),
          orderNote: notifyKitchen(finalBill),
          totalAmount: finalBill,
          salesEntries: selectedOrders
        };
        dispatch(payWaiter(waiterPayload)).then((res) => {
          if (res) {
            navigate('/dashboard');
          }
        });
      } else {
        alert('Oops..! This Order has been paid.');
      }
    }
  };
  const paySelectedHandler = () => {
    if (!selectedOrders.length) {
      alert('Plz Select Items');
      return;
    }
    setIsPaySelected(true);
    let orderPaySelected = { ...order };
    orderPaySelected.salesEntries = selectedOrders;
    setpaySelectedOrder(orderPaySelected);
    const totalSelectedPrice = orderPaySelected.salesEntries?.reduce((acc, order) => {
      const subLineItemPrice = order?.subLineItems?.reduce(
        (subAcc, subItem) => subAcc + subItem.itemPrice,
        0
      );
      return acc + order.itemPrice + subLineItemPrice;
    }, 0);
    setPaySelectedTotal(Number(Number(totalSelectedPrice).toFixed(2)));
  };
  const addItemsForPaySelected = (_itm) => {
    let selectedIndex = selectedOrders.findIndex((data) => data._id === _itm._id);
    let allselectedOrders = [...selectedOrders];
    if (selectedIndex !== -1) {
      allselectedOrders.splice(selectedIndex, 1);
      SetSelectedOrders(allselectedOrders);
      return;
    }
    allselectedOrders.push(_itm);
    SetSelectedOrders(allselectedOrders);
  };
  const selectAllCardItemsHandler = (id) => {
    if (scanedCard == id) {
      let arr = [...selectedOrders, ...filterdfData[id]].filter(
        (_item) => _item.payment_status !== 'paid'
      );
      const uniqueArr = Array.from(new Set(arr.map((obj) => obj._id))).map((id) => {
        return arr.find((obj) => obj._id === id);
      });
      SetSelectedOrders(uniqueArr);
    }
  };
  return (
    <>
      <Row className="p-0 mt-0">
        <Col lg="9" md="12" sm="12" xs="12">
          <div className="newSessionMain">
            {' '}
            <InfoBar />
          </div>
          <Container className="item-wrapper card-list ">
            <Row className="ms-5">
              <Col xs={12} sm={12} md={12} className="d-flex">
                {Object.keys(filterdfData)?.map((card) => {
                  return (
                    <Row
                      className={`w-100 mx-2 ${
                        card !== scanedCard ? 'card-items-row' : 'card-item-row-selected'
                      }`}>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <div
                          onClick={() => {
                            selectAllCardItemsHandler(card);
                          }}
                          className="card-items-row-title">
                          {card}
                        </div>
                        {filterdfData[card].map((item) => {
                          return (
                            <ItemCard
                              selected={
                                selectedOrders.find((order) => order._id === item._id)
                                  ? true
                                  : false
                              }
                              onClick={() => addItemsForPaySelected(item)}
                              dishName={item?.itemName}
                              disabled={item.payment_status == 'paid' ? true : false}
                              quantity={item?.quantity}
                              redItems={item?.redItems}
                              greenItems={item?.greenItems}
                              subLineItems={item?.subLineItems}
                              hasIngredients={
                                'mainIngredients' in item?.ingredients ||
                                'removedStandardIngredients' in item?.redItems ||
                                'extraIngredients' in item?.greenItems
                              }
                              ingredients={item?.ingredients}
                              totalPricewithExtras={
                                Number(item?.itemPrice) +
                                item?.subLineItems?.reduce((acc, item) => {
                                  return Number(acc) + Number(item?.itemPrice);
                                }, 0)
                              }
                            />
                          );
                        })}
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg="3" md="12" sm="12" xs="12">
          <div className="bill-section p-3">
            <span className="order-no">ORDER {order._id.substr(order._id.length - 4)}</span>

            {order.salesEntries?.map((_item) => {
              return (
                <>
                  <div className="dish-order d-flex justify-content-between py-2">
                    <div className="item-count">{_item?.quantity}</div>
                    <div className="items">
                      <h5>{_item?.itemName}</h5>
                      <ul className="text-center">
                        {_item.subLineItems?.map((itm) => {
                          return <li>{itm.itemName}</li>;
                        })}
                      </ul>
                    </div>
                    <div className="item-price">
                      <b>
                        {Number(_item?.itemPrice) +
                          _item?.subLineItems?.reduce((acc, item) => {
                            return Number(acc) + Number(item?.itemPrice);
                          }, 0)}
                      </b>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="blackline mx-2"></div>
            <div className="dish-order d-flex justify-content-between py-2">
              <div className="item-count invisible">2</div>
              <div className="items">
                <div className="d-flex justify-content-start invisible"></div>
              </div>
              <div className="item-price">
                <span className="mx-2">Tot</span>
                <b>{total}€</b>
              </div>
            </div>
            <div className="text-white pt-5 d-flex flex-column align-items-center">
              {loader ? (
                <div className="btn  pay-common  rounded text-white bg-black pt-2 pb-2 mt-3">
                  {' '}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                </div>
              ) : (
                <div
                  onClick={() => payToWaiter()}
                  className="btn  pay-common  rounded text-white bg-black pt-2 pb-2 mt-3">
                  Senden
                </div>
              )}
              {loader ? (
                <div className="btn  pay-common  rounded text-white bg-black pt-2 pb-2 mt-3">
                  {' '}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                </div>
              ) : (
                <div
                  onClick={() => payToWaiterSelected()}
                  className="btn  pay-common  rounded text-white bg-black pt-2 pb-2 mt-3">
                  Auswahl senden
                </div>
              )}
              {/* {loader ? (
                <div className="btn  pay-common  rounded text-white bg-black pt-2 pb-2 mt-5">
                  {' '}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                </div>
              ) : (
                <div
                  onClick={() => paySelectedHandler()}
                  className="btn  pay-common  rounded text-white bg-black pt-2 pb-2 mt-5">
                  Pay Selected
                </div>
              )} */}
              {/* {loader ? (
                <div className="btn  pay-common  rounded text-white bg-black pt-2 pb-2 mt-3">
                  {' '}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                </div>
              ) : (
                <div
                  onClick={() => setShow(true)}
                  className="btn  pay-common  rounded text-white bg-black pt-2 pb-2 mt-3">
                  Pay All
                </div>
              )} */}
            </div>
          </div>
        </Col>
      </Row>
      <PaySelected
        isPaySelected={isPaySelected}
        show={isPaySelected ? isPaySelected : show}
        setShow={isPaySelected ? setIsPaySelected : setShow}
        total={isPaySelected ? paySelectedTotal : total}
        order={isPaySelected ? paySelectedOrder : order}
      />
    </>
  );
};

export default SessionNewOrder;
