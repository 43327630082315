import { GET_SPECIAL } from '../types';

const initialState = {
  special: [],
  loading: true
};

export default function specialReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_SPECIAL: {
      return {
        ...state,
        special: payload
      };
    }

    default:
      return {
        ...state
      };
  }
}
