import ImageRepository from './ImageRepository';
import UserRepository from './UserRepository';
import SpecialRepository from './SpecialRepository';
import Promotions from './PromotionRepository';
import OrderRepository from './OrderRepository';

const repositories = {
  img: ImageRepository,
  user: UserRepository,
  special: SpecialRepository,
  promotions: Promotions,
  order: OrderRepository

};

export const RepositoryFactory = {
  get: (name) => repositories[name]
};
