import axios from 'axios';

// const baseDomain = 'http://localhost:5000';
const baseDomain = 'https://adminapi1.va-piano.ch';
const baseURL = `${baseDomain}`;

const axiosObj = axios.create({
  baseURL
});

const IMG = '/img';
const IMG_DOMAIN = 'https://adminapi1.va-piano.ch/';

export default {
  getLogo() {
    return axiosObj.get(`${IMG}/getlogo`).then((res) => {
      return IMG_DOMAIN + res.data[0].filenme;
    });
  }
};
