import { useEffect, useState } from 'react';
import CountCard from '../../components/Session/CountCard';
import ScannedCardsCol from '../../components/Session/ScannedCardsCol';
import SessionUI from '../../components/Session/SessionUI';
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
import ModalInterface from '../../components/core/ModalInterface';
import { updaterSessionCards, updaterSessionPager } from '../../store/actions/tableActions';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
const ExpandGroup = ({ setLocation }) => {
  const [cardData, setCardData] = useState([]);
  const [isFirstScane, setIsFirstScane] = useState(true);
  const [firstScaneCard, setFirstScaneCard] = useState('');
  const [alreadyExsistingCardsOfSession, setAlreadyExsistingCardsOfSession] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  // const dispatch = useDispatch()
  const UserRepository = RepositoryFactory.get('user');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.table.updateSessionLoader);
  const [pagerNumber, setPagerNumber] = useState(null);
  const [pagerModal, setPagerModal] = useState(false);

  useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  const handleSubmit = () => {
    if (!pagerNumber) {
      setPagerModal(true);
      return;
    }
    handleUpdate();
  };
  const handleUpdate = () => {
    if (!cardData.length) {
      setShowModal(true);
      setErrorMsg('Please scane card first');
      return;
    }
    let busLocID = '771247277342722';
    // TODO have to add tableName  busLocID dynamically
    dispatch(updaterSessionCards({ sessionId, busLocID, cardData })).then((resp) => {
      if (resp.success) {
        setAlreadyExsistingCardsOfSession(resp.data.cards);
        setCardData([]);
        setShowModal(true);
        setErrorMsg('Session updated successfully!');
        navigate('/process-done');
      } else {
        setShowModal(true);
        setErrorMsg('Error Occured' + resp?.data?.message);
      }
    });
  };
  const updatePager = () => {
    if (!pagerNumber) {
      setShowModal(true);
      setErrorMsg('Please Add Pager Number');
      return;
    }
    dispatch(updaterSessionPager({sessionId,pagerNumber})).then((resp) => {
      setPagerModal(false);
      if (!resp.success) {
        setPagerNumber('')
        setPagerModal(false);
        setShowModal(true);
        setErrorMsg('Error Occured' + resp?.data?.message);
      } 
    });
  };
  return (
    <>
      <SessionUI
        pageTitle={'GRUPPE ERWEITERN'}
        cardData={cardData}
        isFirstScane={isFirstScane}
        setIsFirstScane={setIsFirstScane}
        firstScaneCard={firstScaneCard}
        setFirstScaneCard={setFirstScaneCard}
        setAlreadyExsistingCardsOfSession={setAlreadyExsistingCardsOfSession}
        alreadyExsistingCardsOfSession={alreadyExsistingCardsOfSession}
        setCardData={setCardData}
        imgCardColTitle={isFirstScane ? 'SCAN FIRST CARD' : 'SCAN CARD TO ADD'}
        BESTÄTIGEN={() => navigate('/gruppe-process-done')}
        ABBRECHEN={() => navigate('/dashboard')}
        variant_ABBRECHEN={'vapiano-prim'}
        variant_BESTÄTIGEN={'vapiano-danger'}
        loading={loading}
        onClickFunc={handleSubmit}
        setSessionId={setSessionId}
        setPagerNumber={setPagerNumber}
        pagerNumber={pagerNumber}
        scannedCardCol={
          <ScannedCardsCol
            setCardData={setCardData}
            cardData={cardData}
            alreadyExsistingCardsOfSession={alreadyExsistingCardsOfSession}
            ScanCardsColTitle={'SCANNED CARDS'}
            card_img_placeholder={'creditcard.png'}
            trashCan={true}
          />
        }
        cardImgSrc={'card_mark.png'}
        thirdColCard={
          <CountCard
            ScanCardsColTitle={'  GESCANNTE PAGER'}
            countData={cardData.length + alreadyExsistingCardsOfSession?.length}
            card_img_placeholder={'creditcard.png'}
            pagerNumber={pagerNumber}
            isPager={true}
            trashCan={true}
            setpagerNumber={setPagerNumber}
          />
        }
      />
      <ModalInterface
        display={showModal}
        size="sm"
        footer={true}
        style={{ position: 'relative' }}
        handleClose={() => {
          setShowModal(false);
        }}
        bodyStyles={{ maxHeight: '100%', overflowY: 'scroll' }}
        content={<>{errorMsg}</>}
        cartFooter={<></>}
        className={'text-dark pt-2'}
        closeEvent={() => setShowModal(false)}
      />
        <ModalInterface
        display={pagerModal}
        size="lg"
        footer={true}
        style={{ position: 'relative' }}
        handleClose={() => {
          setPagerModal(false);
          setPagerNumber("");
        }}
        bodyStyles={{ maxHeight: '100%', overflowY: 'scroll' }}
        content={
          <>
            <Form.Control
              value={pagerNumber}
              onChange={(e) => {
                setPagerNumber(e.target.value);
              }}
              type="number"
              placeholder="Pager Number"
            />
          </>
        }
        footerContent={
          <>
            <Button
              onClick={() => {
                updatePager()
              }}
              style={{ backgroundColor: '#3498DB', border: 'none' }}
              type="submit">
              {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
            ) : (              
              "Submit"
            )}
            </Button>
          </>
        }
        className={'text-dark pt-2'}
        closeEvent={() => setPagerModal(false)}
      />
    </>
  );
};

export default ExpandGroup;
