export const ingredients = {
  mainIngredients: [{ name: 'TRUFFEL PINSA', price: '23' }],
  removedStandardIngredients: [
    { name: 'edfwed', price: '23' },
    { name: 'sdfs', price: '23' }
  ],
  extraIngredients: [
    { name: 'sdfx', price: '23' },
    { name: 'isdfs', price: '23' }
  ]
};

export const saleEntries = {
  paidItems: [
    {
      dishName: 'sdfsd',
      basicItemPrice: 13,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 9.5,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    }
  ],
  scannedItems: [
    {
      dishName: 'sdfsd',
      basicItemPrice: 13,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 9.5,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    }
  ],
  unScannedItems: [
    {
      dishName: 'sdfsd',
      basicItemPrice: 13,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 9.5,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    },
    {
      dishName: 'sdfsd',
      basicItemPrice: 7.3,
      quantity: 2,
      totalPricewithExtras: 324,
      ingredients: ingredients
    }
  ]
};
