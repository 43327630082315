import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './sessionUI.scss';
import Card from 'react-bootstrap/Card';
import Scan from '../../views/ScaneCard/scane';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
import ModalInterface from '../core/ModalInterface';
import { useDispatch } from 'react-redux';
import { getExsistingSesion } from '../../store/actions/tableActions';
const SessionUI = ({
  ABBRECHEN,
  BESTÄTIGEN,
  imgCardColTitle,
  thirdColCard,
  pageTitle,
  cardImgSrc,
  scannedCardCol,
  variant_ABBRECHEN,
  variant_BESTÄTIGEN,
  setCardData,
  cardData,
  isFirstScane,
  setIsFirstScane,
  setFirstScaneCard,
  firstScaneCard,
  setAlreadyExsistingCardsOfSession,
  alreadyExsistingCardsOfSession,
  loading,
  onClickFunc,
  setSessionId,
  isPayment,
  setPagerNumber,
  setMergeCardId,
  refetchcardSession,
  setRefectchCardSession,
  pagerNumber
}) => {
  const [actions, setActions] = useState(null);
  const { scan } = actions || {};
  const UserRepository = RepositoryFactory.get('user');
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();
  const onHandleAction = (actions) => {
    setActions({ ...actions });
  };
  const actionsValue = { actions, onHandleAction };
  const getExsistingSesionHandler = (id) => {
    dispatch(getExsistingSesion(id)).then((resp) => {
      if (resp.success) {
        setFirstScaneCard('');
        setIsFirstScane(false);
        setSessionId(resp?.data[0]?._id);
        setAlreadyExsistingCardsOfSession(resp?.data[0]?.cards);
        if (setPagerNumber) {
          setPagerNumber(resp?.data[0]?.pagerNumber);
        }
      } else {
        setFirstScaneCard('');
        setShowModal(true);
        setErrorMsg('Error Occured');
      }
      if (refetchcardSession) {
        setRefectchCardSession('');
      }
    });
  };

  useEffect(() => {
    if (firstScaneCard) {
      getExsistingSesionHandler(firstScaneCard);
    }
  }, [firstScaneCard]);
  useEffect(() => {
    if (refetchcardSession) {
      getExsistingSesionHandler(refetchcardSession);
    }
  }, [refetchcardSession]);


  return (
    <>
      <Container className="my-5">
        <h1 className="mt-5 pt-3">{pageTitle}</h1>
        <Row className="gap-3 p-4 justify-content-center registration-wrapper">
          <Col className="bg-card rounded" xs={12} sm={12} md={5}>
            <Card className="rounded pt-2 pb-3">
              {' '}
              <Card.Title className="text-start w-100">{imgCardColTitle}</Card.Title>
              <Card.Body
                onClick={() => {
                  onHandleAction({ scan: 'scanning', write: null });
                }}
                className="img-container-1 bg-white d-flex align-items-center justify-content-center pointer-hand">
                <Card.Img variant="bottom" src={`img/${cardImgSrc}`} className="img-card" />
              </Card.Body>
            </Card>
          </Col>

          {scannedCardCol}

          {thirdColCard}
        </Row>

        <Row className="d-flex justify-content-between mt-2 mb-2">
          <Button variant={variant_ABBRECHEN} className="text-white" size="lg" onClick={ABBRECHEN}>
            ABBRECHEN
          </Button>
          <Button
            className="text-white "
            variant={variant_BESTÄTIGEN}
            size="lg"
            disabled={loading}
            onClick={onClickFunc}>
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
            ) : (
              // {pagerNumber ? " BESTÄTIGEN":"Pager zuweisen"}
              
                pagerNumber?"BESTÄTIGEN":"Pager zuweisen"
              
            )}
          </Button>
        </Row>
      </Container>
      <ModalInterface
        display={showModal}
        size="sm"
        footer={true}
        style={{ position: 'relative' }}
        handleClose={() => {
          setShowModal(false);
        }}
        bodyStyles={{ maxHeight: '100%', overflowY: 'scroll' }}
        content={<>{errorMsg}</>}
        cartFooter={<></>}
        className={'text-dark pt-2'}
        closeEvent={() => setShowModal(false)}
      />
      {scan === 'scanning' && (
        <Scan
          setShowModal={setShowModal}
          setErrorMsg={setErrorMsg}
          alreadyExsistingCardsOfSession={alreadyExsistingCardsOfSession}
          isFirstScane={isFirstScane}
          setIsFirstScane={setIsFirstScane}
          setFirstScaneCard={setFirstScaneCard}
          setCardData={setCardData}
          cardData={cardData}
          actionsValue={actionsValue}
          isPayment={isPayment}
          setMergeCardId={setMergeCardId}
        />
      )}
    </>
  );
};

export default SessionUI;
